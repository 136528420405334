<template>
  <v-layout v-if="isTracking" row fill-height align-center>
    <v-flex v-if="!!eta" class="vehicle-eta-column">(ETA) {{ eta }}</v-flex>
    <v-flex>
      <CalculateEtaButton
        v-if="vehicleStatus === 'EN ROUTE' || vehicleStatus === 'TO DO'"
        :stop-id="stopId"
        :vehicle-address="vehicleAddress"
        :button-type="buttonType"
        class="pl-0"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import CalculateEtaButton from '@/components/CalculateEtaButton.vue'
import {
  secondsToDaysAndHoursStringShortened,
  ianaZoneToOffsetName,
} from '@/utils/time'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  components: {
    CalculateEtaButton,
  },
  props: {
    stopId: { type: Number, default: null },
    vehicleId: { type: Number, default: null },
    vehicleStatus: { type: String, default: 'TO DO' },
    address: { type: Object, required: true },
    minRefresh: { type: Number, default: -300 },
    isTracking: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      distanceAndDuration: 'trackingDevices/distanceAndDuration',
    }),
    buttonType() {
      if (this.estimate) {
        return 'refresh-icon'
      }
      return 'text'
    },
    vehicleAddress() {
      return new Map([[this.vehicleId, this.address]])
    },
    estimate() {
      return this.distanceAndDuration(this.vehicleId, this.address.addressId)
    },
    eta() {
      if (this.estimate) {
        const { timeZone, arrivalTime, duration } = this.estimate.stop
        const eta = `
        ${DateTime.fromISO(arrivalTime, {
          zone: timeZone,
        }).toLocaleString(DateTime.TIME_SIMPLE)}
        ${ianaZoneToOffsetName(timeZone, arrivalTime)},
        ${secondsToDaysAndHoursStringShortened(duration)}`
        return eta
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-eta-column {
  max-width: 140px !important;
}
</style>
