<template class="table-style">
  <DataTable
    ref="dataTable"
    class="table-style"
    v-bind="tableProps"
    style="margin-top: -15px; z-index=0;"
  />
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import AuditStatusChangeDetail from '@/components/AuditStatusChangeDetail.vue'
import * as logger from '@/utils/logger'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import { calculatedValues, datePredefined, textLike } from '@/utils/predefined'
import { DateTime } from 'luxon'
import { PoStatus } from '@/utils/enum'

export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Reservation Logs',
    }
  },
  props: {
    reservationStatus: { type: String, default: '' },
    id: { type: String, default: null },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      categoryParentId: undefined,
      selectedCategory: undefined,
      itemsPerPage: -1,
      page: 1,
      tableProps: {
        enableExport: false,
        enableStatusFilterToggle: true,
        tableId: 'reservationAudits_table_view',
        total: 0,
        currentPage: 1,
        perPage: -1,
        list: [],
        sort: this.sort,
        hideActions: true,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detailKeyId: 'reservationAuditId',
        isDetailed: true,
        showCollapseIcon: true,
        collapseIconIndex: 0,
        detail: AuditStatusChangeDetail,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        calculatedValues,
        columns: [
          {
            _t_id: 'f4dfd5e0',
            prop: 'reservationAuditId',
            text: 'ID',
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            filterType: 'eq',
            defaultSort: true,
          },
          {
            _t_id: 'f4dfd5e1',
            prop: 'reservation/managedId',
            text: 'Reservation ID',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            displayType: 'clickable-company',
            filterType: 'contains',
            sortProp: 'reservation/managedId',
            filterProp: 'reservation/managedId',
            defaultSort: true,
          },
          {
            _t_id: 'f4d3de64',
            prop: 'datetime',
            text: 'Time',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('M/d/yyyy h:mm a ZZZZ'),
            sort: true,
            filter: true,
            type: 'date',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'f4dfde64',
            prop: ['user/firstName', 'user/lastName'],
            text: 'User',
            sort: true,
            filter: true,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            displayType: 'action-item',
            sortProp: 'user/lastName',
            filterType: 'contains',
            childMethod: 'and',
          },
          {
            _t_id: 'f4dfde66',
            prop: 'companyName',
            text: 'Company',
            sort: true,
            filter: true,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            detail: false,
            displayType: 'action-item',
            sortProp: 'companyName',
            filterType: 'contains',
            childMethod: 'and',
          },
          {
            _t_id: 'f4dfd974',
            prop: 'actionType',
            text: 'Action',
            sort: true,
            filter: true,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            detail: false,
            sortProp: 'actionType',
            filterType: 'contains',
            method: 'and',
            childMethod: 'and',
            predefined: textLike,
            computedText: (item, row) => this.actionText(item, row),
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    reservationStatus() {
      this.$refs.dataTable.refreshQuery()
    },
  },
  mounted() {},
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const { company } = this.currentUser

      this.tableProps.loading = true

      const params = {
        sorts,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        companyId: company.companyId,
        reservationId: this.$attrs.reservationId,
      }

      const reservationAudits = await this.$store
        .dispatch('reservations/reservationAuditTVByReservationId', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })

      if (reservationAudits) {
        this.tableProps.perPage = -1
        this.tableProps.currentPage = 1
        this.tableProps.list = reservationAudits.data.resultList
        this.tableProps.total = reservationAudits.data.count
        this.tableProps.loading = false
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = 1
      this.itemsPerPage = -1
      this.refresh()
    },
    actionText(item, row) {
      if (row.actionType === 'MARGE Enabled' && row.newValue === 'true') {
        return 'MARGE Disabled'
      } else if (
        row.actionType === 'MARGE Enabled' &&
        row.newValue === 'false'
      ) {
        return 'MARGE Started'
      } else if (row.actionType === 'Unassigned' && row.newValue) {
        return `${row.actionType} (${row.newValue.split('|')[1]})`
      } else if (
        (row.actionType === 'Accepted' ||
          row.actionType === 'Accepted Edited Referral') &&
        row.previousValue
      ) {
        return `${row.actionType} (${row.previousValue.split('|')[1]})`
      } else if (
        (row.actionType === 'Offered' ||
          row.actionType === 'Offered - Availability Override' ||
          row.actionType === 'Offered Edited Referral') &&
        row.newValue
      ) {
        return `${row.actionType} (${row.newValue.split('|')[1]})`
      } else if (row.actionType === PoStatus.Confirmed || row.actionType === PoStatus.Received) {
        return `${row.actionType} - #${row.newValue}`
      } else {
        return row.actionType
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  cursor: pointer;
}
</style>
<style scoped lang="scss">
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep {
  .v-table__overflow {
    border: 1px solid $gray-light;

    table.v-datatable {
      thead tr {
        height: 55px;
      }

      tbody {
        tr td:first-of-type {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
