import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { ApiResult, TripExperienceNotificationSetting, TripExperienceNotificationSettingResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

/**
 * Gets the trip experience notification settings for a given reservation.
 * @param reservationId - the id of the reservation.
 * @returns a TripExperienceNotificationSettingResult.
 */
export const getTripExperienceNotificationSettingsByReservationId = (
  reservationId: number
): Promise<AxiosResponse<TripExperienceNotificationSettingResult>> => {
  const host = baseUrl()
  const url = `https://${host}/tripExperienceNotification/${reservationId}`
  return httpService.get(url)
}

/**
 * Updates a trip experience notification setting.
 * @param tripExperienceNotificationSettingId - the id of the setting to update.
 * @param payload - the TripExperienceNotificationSetting object.
 * @returns a response indicating whether the update was successful.
 */
export const updateTripExperienceNotificationSettingsById = (
  tripExperienceNotificationSettingId: number,
  payload: TripExperienceNotificationSetting
): Promise<AxiosResponse<ApiResult>> => {
  const host = baseUrl()
  const url = `https://${host}/tripExperienceNotification/${tripExperienceNotificationSettingId}`
  return httpService.patch(url, payload)
}

/**
 * Updates a reservation's trip experience notification settings to be on/off.
 * @param reservationId - the reservation id to update.
 * @param areTripExperienceNotificationsEnabled - a boolean indicating whether notifications should be on or off.
 * @returns a response indicating whether the update was successful.
 */
export const updateMasterTripExperienceNotificationSettingsByReservationId = (
  reservationId: number,
  areTripExperienceNotificationsEnabled: boolean
): Promise<AxiosResponse<ApiResult>> => {
  const host = baseUrl()
  const payload = { areTripExperienceNotificationsEnabled }
  const url = `https://${host}/tripExperienceNotification/reservation/${reservationId}`
  return httpService.patch(url, payload)
}
