<template>
  <v-layout column>
    <TicketsList
      :reservation-id-filter="reservationId"
      :managed-id="managedId"
      :reasons="reasonMap"
      hide-details
      hide-reservation-ids
    />
  </v-layout>
</template>

<script>
import { DateTime } from 'luxon'
import TicketsList from '@/views/TicketsList.vue'

export default {
  components: {
    TicketsList,
  },
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    managedId: {
      type: Number,
      default: null,
    },
    reservationTickets: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
    reasonMap: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Ticket #',
          value: 'ticketId',
          align: 'center',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Assigned To',
          value: 'assignedTo',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Description',
          value: 'comments',
          sortable: false,
          width: '50%',
        },
      ],
    }
  },
  mounted() {},
  methods: {
    createdAtDatetime(createdAtString) {
      return DateTime.fromISO(createdAtString).toLocaleString(
        DateTime.DATE_SHORT
      )
    },
    addTicket() {
      this.$router.push({
        name: 'tickets.add',
        params: { reservationId: this.reservationId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-table__overflow {
    border: 1px solid $gray-light;

    table.v-datatable {
      thead tr {
        height: 55px;

        th:first-of-type {
          text-align: center;
        }
      }

      tbody {
        tr td:first-of-type {
          text-align: center;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.add-button {
  width: 100px;
}

::v-deep .sheet {
  padding: 0;
}

::v-deep .status-filters,
::v-deep .filter-tags {
  display: none;
}
</style>
