import { render, staticRenderFns } from "./ReservationReferrals.vue?vue&type=template&id=8e32dba2&scoped=true"
import script from "./ReservationReferrals.vue?vue&type=script&lang=js"
export * from "./ReservationReferrals.vue?vue&type=script&lang=js"
import style0 from "./ReservationReferrals.vue?vue&type=style&index=0&id=8e32dba2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e32dba2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VChip,VFlex,VLayout,VProgressLinear,VSpacer,VTooltip})
