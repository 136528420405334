
import { RiskTypeId } from '@/utils/enum'
import { Risk } from '@/models/dto'

/**
 * Checks if the given list of risks includes a risk of a specific type that is active and not resolved.
 *
 * @param risks - Array of risk objects to be checked.
 * @param riskTypeId - The ID of the risk type to check for.
 * @returns `true` if there is at least one risk in the array that matches the criteria; otherwise, `false`.
 */
export const includesRisk = (risks: Risk[], riskTypeId: RiskTypeId): boolean => {
  if (!risks) {
    return false
  }
  return risks
    .filter((risk): risk is Risk => !!risk)
    .some(
      (risk) =>
        risk.riskTypeId === riskTypeId && risk.active && !risk.resolved
    )
}

/**
 * An array of risk type IDs considered to be at the stop level.
 */
export const STOP_LEVEL_RISKS: RiskTypeId[] = [
  RiskTypeId.FlightRisk,
  RiskTypeId.MissingStopRisk,
  RiskTypeId.IncompleteStopRisk,
  RiskTypeId.MissingPickupTimeRisk,
]

/**
 * An array of risk type IDs considered to be at the trip level.
 */
export const TRIP_LEVEL_RISKS: RiskTypeId[] = [
  RiskTypeId.DriverHotelRisk,
  RiskTypeId.DOTComplianceRisk,
]
