
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PaymentTerms, ReferralStatus, SplitFeatureFlag } from '@/utils/enum'

const paymentTermsTitles = {
  [ PaymentTerms.Net3 ]: "Net-3 Terms",
  [ PaymentTerms.Net14 ]: "Net-14 Terms",
  [ PaymentTerms.Pending ]: "Pending",
}

const paymentTermsIcon = {
  [ PaymentTerms.Net3 ]: "mdi-flash",
  [ PaymentTerms.Net14 ]: "mdi-flash-off",
  [ PaymentTerms.Pending ]: "mdi-flash-off"
}

@Component
export default class ReservationPaymentsPaymentTerms extends Vue {
  @Prop({ type: String, default: null }) readonly originalTerms: string
  @Prop({ type: String, default: null}) readonly paymentTerms: string
  @Prop({ type: String, default: null}) readonly referralStatus: string
  @Prop({ type: Boolean, default: false}) readonly canViewPaymentTerms: boolean

  isPaymentTermsDisputesEnabled: boolean = false

  get isPaymentTermsVisible() {
    return !!this.paymentTerms
      && this.referralStatus !== ReferralStatus.Rejected
      && this.canViewPaymentTerms
  }

  get paymentTermsTitle() {
    if (!this.isPaymentTermsVisible) {
      return ""
    }

    return paymentTermsTitles[this.paymentTerms] + (this.isTermsUpdated && this.isPaymentTermsDisputesEnabled ? ' *' : '')
  }

  get isNet3Terms() {
    return this.isPaymentTermsVisible && this.paymentTerms === PaymentTerms.Net3
  }

  get isNet14Terms() {
    return this.isPaymentTermsVisible && this.paymentTerms === PaymentTerms.Net14
  }

  get isPendingTerms() {
    return this.isPaymentTermsVisible && this.paymentTerms === PaymentTerms.Pending
  }

  get isTermsUpdated() {
    return this.originalTerms !== this.paymentTerms
  }

  get iconClassObject() {
    return {
      'net-3-icon': this.isNet3Terms,
      'net-14-icon': this.isNet14Terms,
      'pending-icon': this.isPendingTerms
    }
  }

  get paymentTermsIcon() {
    if (!this.isPaymentTermsVisible) {
      return ""
    }

    return paymentTermsIcon[this.paymentTerms]
  }

  @Watch('$store.state.split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (isReady) {
      this.isPaymentTermsDisputesEnabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.PaymentTermsDisputes)
    }
  }
}
