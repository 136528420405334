
import { AmenityType } from '@/models/dto/Amenity'
import { AmenityTypeId } from '@/utils/enum'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ReservationAmenities extends Vue {
  @Prop({ type: Array, required: true }) readonly tripAmenities: AmenityType[]

  get filteredAmenities(): AmenityType[] {
    return this.tripAmenities
      .filter(amenity => ![AmenityTypeId.Bathroom, AmenityTypeId.Luggage, AmenityTypeId.Outlets].includes(amenity.id))
  }
}

