
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isoToString } from '@/utils/time'
import * as logger from '@/utils/logger'
import {
  reservationStatusTypeMap,
  cancellationStatusMap,
} from '@/utils/reservation'

import Sheet from '@/layouts/Sheet.vue'
import ReservationActionsMenu from '@/components/ReservationActionsMenu.vue'
import ReservationOverview from '@/components/ReservationOverview.vue'
import ReservationComponentSelector from '@/components/ReservationComponentSelector.vue'
import customerAccounts from '@/services/customerAccounts'
import CRTag from '@/cr/components/CRTag.vue'
import TierBadge from '@/components/TierBadge.vue'
import { EventBus } from '@/utils/event-bus'
import { getClassifications } from '@/services/reservations'
import {
  Reservation,
  CustomerAccount,
  StagePaymentMethod,
  PaymentMethodType,
} from '@/models/dto'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({
  components: {
    Sheet,
    ReservationActionsMenu,
    ReservationOverview,
    ReservationComponentSelector,
    CRTag,
    TierBadge,
  },
})
export default class ReservationDetail extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly ignoreCache: boolean

  metaInfo() {
    return {
      title: `Reservation ${this.reservationId}`,
    }
  }

  loading: boolean = true
  refreshingDetail: boolean = false
  markAcceptedLoading: boolean = false
  usageTrackingFlag: boolean = false
  customerAccount: CustomerAccount = null
  paymentMethodTypes: PaymentMethodType[] = []
  balancePaymentMethods: StagePaymentMethod[] = []
  paymentTypeId: number = null
  reservationUnavailable: boolean = false
  reasonMap: any = {}
  reasonTypeLevels: any[] = []
  shouldIgnoreCache: boolean = false
  reservation: Reservation = null
  isTiersEnabled: boolean = false

  isoToString = isoToString

  @Watch('$route', { immediate: true })
  async onRouteChange(to: any, from: any) {
    if (to?.path === from?.path) {
      return
    }

    await this.resetReservationDetail()
  }

  async mounted() {
    this.shouldIgnoreCache = this.ignoreCache
    await this.resetReservationDetail()
    await this.getComplaintReasonTypes()

    const quoteTypesData = await this.$store.dispatch('types/getQuoteTypes')
    const quoteTypes = quoteTypesData.data || {}
    this.paymentMethodTypes = quoteTypes?.paymentMethods

    this.isTiersEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled', SplitFeatureFlag.ServiceTier
    )

    EventBus.$on('refresh-detail', async () => {
      await this.handleRefresh()
    })
  }

  beforeDestroy() {
    EventBus.$off('refresh-detail')
  }

  get cachedReservation(): Reservation | null {
    if (!this.reservationsCache || this.reservationUnavailable) {
      return null
    }
    return this.reservationsCache[this.$route.params.id]
  }

  get reservationAndReasons(): any {
    return {
      ...this.reservation,
      reasons: this.reasonTypeLevels,
      reasonMap: this.reasonMap,
    }
  }

  get reservationStatusTypeString(): string {
    const reservationStatus = this.reservation?.reservationStatus
    return reservationStatus ? reservationStatusTypeMap[reservationStatus] : ''
  }

  get cancellationStatusString(): string {
    const cancellationStatus = this.reservation?.cancellationStatusKey
    return cancellationStatus ? cancellationStatusMap[cancellationStatus] : ''
  }

  get reservationId(): string | number {
    return this.reservation?.managedId || this.reservation?.reservationId
  }

  get tripName(): string {
    return this.reservation?.routeName || ''
  }

  get reservationsCache() {
    return this.$store.getters['reservations/reservationsCache'] || {}
  }

  get currentUserProfile() {
    return this.$store.getters['auth/currentUserProfile'] || {}
  }

  get currentUser() {
    return this.$store.getters['auth/currentUser'] || {}
  }

  get showTierMarker() {
    if (this.isTiersEnabled && this.reservation?.tier) {
      return this.reservation.tier.level > 1
    }
    return false
  }

  setReservationData(reservationData): void {
    this.reservation = Object.assign({}, this.reservation, reservationData)
  }

  async setUsageTrackingFlag(): Promise<void> {
    if (!this.reservation.tripId) {
      await this.updateCachedReservation()
    }
    const { data } = await this.$store.dispatch(
      'trips/getTrip',
      this.reservation.tripId
    )
    if (data.customer.customerAccountId) {
      const {
        data: customerAccountData,
      } = await customerAccounts.getCustomerAccount(
        data.customer.customerAccountId
      )
      this.customerAccount = customerAccountData
    }

    if (data.stagePaymentMethods) {
      this.balancePaymentMethods = data.stagePaymentMethods.balancePaymentMethods
    }

    this.paymentTypeId = data.paymentTypeId

    this.usageTrackingFlag = data.usageTrackingFlag
  }

  async resetReservationDetail(): Promise<void> {
    this.loading = true
    if (
      !this.reservationsCache[this.$route.params.id] ||
      this.shouldIgnoreCache
    ) {
      const reservation = await this.$store.dispatch(
        'reservations/fetchReservationById',
        {
          reservationId: this.$route.params.id,
          companyId: this.currentUser?.companyId,
          viewRejectedReferrals: true,
          force: this.shouldIgnoreCache,
        }
      )
      this.setReservationData(reservation)
      EventBus.$emit('reservation-itinerary-update')
      this.shouldIgnoreCache = false
    } else {
      this.setReservationData(this.reservationsCache[this.$route.params.id])
    }
    await this.setUsageTrackingFlag()
    this.loading = false
  }

  async markAccepted(referralAcceptanceESignature = ''): Promise<void> {
    this.markAcceptedLoading = true
    const params = {
      reservationId: this.reservation.reservationId,
      referralId: this.reservation.referralId,
      companyId: this.currentUser?.companyId,
      refetchId: this.reservation.reservationId,
      referralAcceptanceESignature,
    }
    try {
      await this.$store.dispatch('reservations/acceptReferral', params)
    } catch (err) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error accepting referral, please try again',
      })
      logger.error(err)
      this.markAcceptedLoading = false
    }
    this.markAcceptedLoading = false
  }

  async markRejected(reasonType, notes): Promise<void> {
    this.markAcceptedLoading = true
    const payload = {
      reservationId: this.reservation.reservationId,
      referralId: this.reservation.referralId,
      companyId: this.currentUser?.companyId,
      refreshReservationId: this.reservation.reservationId,
      body: {
        referralRejectionReasonTypeId: reasonType,
        notes: notes,
      },
    }
    try {
      await this.$store.dispatch('reservations/rejectReferral', payload)
    } catch (err) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error rejecting referral, please try again',
      })
      logger.error(err)
      this.markAcceptedLoading = false
    }

    this.$router.push({ name: 'reservations' })
  }

  async handleRefresh(): Promise<void> {
    this.refreshingDetail = true
    await this.updateCachedReservation()
    await this.setUsageTrackingFlag()
    this.setReservationData(this.reservationsCache[this.$route.params.id])
    this.refreshingDetail = false
  }

  async updateCachedReservation(): Promise<void> {
    const response = await this.$store.dispatch(
      'reservations/refreshReservationById',
      {
        reservationId: this.$route.params.id,
        companyId: this.currentUser?.companyId,
        viewRejectedReferrals: true,
      }
    )
    this.reservationUnavailable = response?.reservationId === null
  }

  async getComplaintReasonTypes(): Promise<void> {
    const complaintReasonTypes = await getClassifications('complaint_reason')
    const complaintReasonLevels = complaintReasonTypes?.data?.data
    for (let reason of complaintReasonLevels) {
      this.addReasonAndChildren(reason, 0)
    }
    this.reasonTypeLevels[1].sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    )
  }

  addReasonAndChildren(reason, level): void {
    if (reason == null) {
      return
    }

    if (this.reasonTypeLevels.length <= level) {
      this.reasonTypeLevels.push([])
    }
    this.reasonTypeLevels[level].push(reason)
    let cid = `${reason.classificationId}`
    this.reasonMap[cid] = reason
    if (reason.childClassifications == null) return
    for (let child of reason.childClassifications) {
      this.addReasonAndChildren(child, level + 1)
    }
  }
}
