<template>
  <div style="max-width: 24px !important">
    <v-menu bottom transition="slide-y-transition">
      <template #activator="{ on: { click } }">
        <CRIcon
          class="cursor-pointer"
          color="primary"
          view-box="0 0 28 28"
          @click.native="click"
        >
          more_vert
        </CRIcon>
      </template>
      <v-list>
        <template v-for="item of actions">
          <v-list-tile
            v-if="item"
            :id="`action-require-wrapper-${item.id}`"
            :key="item.id"
            @click="item.action"
          >
            <v-list-tile-title>
              <div class="action-link">
                <span class="action-text">{{ item.title }}</span>
              </div>
            </v-list-tile-title>
          </v-list-tile>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    actions: { type: Array, default: () => [] },
  },
}
</script>
