<template>
  <div>
    <v-layout row>
      <v-flex xs3>
        <v-btn-toggle
          v-model="reservationSelectionIndex"
          mandatory
          class="type-toggler"
        >
          <v-btn depressed active-class="primary" :value="0">Reservation</v-btn>
          <v-btn
            depressed
            active-class="primary"
            :value="1"
            :disabled="!referredTo.length"
          >
            Referral
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex v-show="reservationSelectionIndex === 1" xs3>
        <v-menu ref="menuRef">
          <template #activator="{ on }">
            <v-btn class="btn-secondaryaction action-button" v-on="on">
              {{ currentReferral.managedId }} -
              {{ currentReferral.companyName }} &nbsp;&nbsp;
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-for="(referral, index) in computedReferredTo"
              :key="index"
              @click.stop="setReferral(referral)"
            >
              <v-list-tile-title>
                {{ referral.managedId }} - {{ referral.companyName }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs5>
        <div class="comments-list-container">
          <div class="comments-list">
            <div v-if="comments.length === 0" class="comment-container">
              <span class="comment-body">No comments.</span>
            </div>
            <div
              v-for="(comment, commentIndex) in comments"
              :key="commentIndex"
              class="comment-container"
            >
              <span class="comment-author">{{ commentAuthor(comment) }}</span>
              <br />
              <span class="comment-body">{{ comment.comment }}</span>
            </div>
          </div>
        </div>
      </v-flex>
      <v-spacer />
      <v-flex text-xs-right xs6>
        <v-form ref="commentForm" v-model="validComment" lazy-validation>
          <v-flex>
            <v-textarea
              :id="`${id}-reservation-comments-textarea-comment`"
              ref="commentBody"
              v-model="newComment"
              :outline="validComment ? false : true"
              :rules="isRequired('Comment must not be empty')"
              class="comment-box"
              rows="10"
              maxlength="3000"
              flat
              placeholder="Add a Comment"
              solo
            />
          </v-flex>
          <v-flex text-xs-right xs12>
            <v-btn
              :id="`${id}-reservation-comments-button-add-comment`"
              class="btn-primaryaction"
              :color="validComment ? '' : 'error'"
              @click="addReservationComment"
            >
              Add
            </v-btn>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    reservationComments: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
    referredTo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newComment: '',
      validComment: true,
      submitted: false,
      currentReferral: this.referredTo.length ? this.referredTo[0] : null,
      reservationSelectionIndex: 0,
    }
  },
  computed: {
    comments() {
      const sortByDate = (a, b) =>
        DateTime.fromISO(a.createdOn) > DateTime.fromISO(b.createdOn) ? -1 : 0
      const commentsOut =
        this.reservationSelectionIndex === 0
          ? deepClone(this.reservationComments)
          : deepClone(this.referralComments).filter(
              (rc) => rc.reservationId === this.currentReferral?.reservationId
            )
      commentsOut.sort(sortByDate)
      return commentsOut
    },
    referralComments() {
      return this.referredTo
        .map((rt) => rt.referralComments)
        .reduce((pre, cur) => {
          return pre.concat(cur)
        })
    },
    computedReferredTo() {
      return this.referredTo.map((rt) => {
        return {
          ...rt,
          label: `${rt.managedId} - ${rt.companyName}`,
        }
      })
    },
  },
  methods: {
    async setReferral(referral) {
      const parentMenu = this.$refs.menuRef
      if (parentMenu) {
        parentMenu.isActive = false
      }

      this.currentReferral = referral
    },
    commentAuthor(comment) {
      const commentDateTime = DateTime.fromISO(
        comment?.createdOn
      ).toLocaleString(DateTime.DATETIME_SHORT)
      return `${commentDateTime} by ${comment?.userName} from ${comment?.companyName}`
    },
    isRequired(message) {
      const isEmpty = (value) => {
        if (!this.submitted) {
          return true
        }
        if (typeof value === 'undefined') {
          return false
        }
        if (typeof value === 'string') {
          return value.length > 0
        }
        return false
      }
      return [(value) => isEmpty(value) || message]
    },
    addReservationComment() {
      this.submitted = true
      const { value: comment = '' } = this.$refs.commentBody
      this.validComment = this.$refs.commentForm.validate()
      if (!this.validComment) {
        this.validComment = false
        return
      }
      const params = {
        reservation: {
          id:
            this.reservationSelectionIndex === 0
              ? this.reservationId
              : this.currentReferral.reservationId,
        },
        refreshId: this.reservationId,
        note: comment,
      }
      this.$store.dispatch('reservations/addReservationComment', params)
      this.submitted = false
      this.newComment = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.comments-list-container {
  max-height: 200px;
  margin-top: 0;
  overflow: auto;

  .comments-list {
    padding: 4px;
    margin: 4px;
  }

  .comment-container {
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 15px;
    }

    .comment-author {
      color: $gray-medium-light;
    }
  }
}

.v-form {
  margin-top: 0;
}

.type-toggler {
  margin-right: 1em;
  border-radius: 5px;
  box-shadow: none;

  .v-btn {
    height: unset;
    padding: 15px 40px;

    &.primary {
      color: $white;
    }
  }
}

.v-btn {
  margin-right: 0 !important;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 0 !important;
}
</style>
