import Vue from 'vue'
import { store } from '@/state/store'

export default async function (component, user) {
  const MenuDataMapper = function (row) {
    return [
      {
        icon: () => 'edit',
        viewBox: '0 0 24 24',
        title: () => 'Update Effective Date',
        click: async () => {
          const component = () =>
            import('./EditPaymentsEffectiveDateSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              transactionId: row.transactionId,
              paymentId: row.paymentId,
              refundId: row.refundId,
              effectiveDatetime: row.effectiveDate,
              title: `Edit Effective Date`,
            },
            component,
          })
        },
      },
      {
        icon: () => 'copy',
        title: () => `Copy Transaction ID`,
        click: async () => {
          navigator.clipboard.writeText(row?.referenceId)
          store.dispatch('app/showAlert', {
            type: 'success',
            message: `Transaction ID Copied to Clipboard: \n ${row.referenceId}`,
          })
        },
        condition: () => {
          return row?.referenceId != null
        },
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            user
          ),
        },
      })
    },
  })
}
