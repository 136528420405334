<template>
  <v-layout column>
    <v-layout row class="header-row">
      <v-flex
        v-for="header in headers"
        :key="header.text"
        :class="`xs${header.size}`"
        style="padding-left: 24px"
      >
        <div :class="header.type">
          {{ header.text }}
        </div>
      </v-flex>
    </v-layout>

    <v-layout
      v-for="(ref, i) in displayProps"
      :key="i"
      row
      class="row-container"
    >
      <v-flex
        v-for="(header, h) in headers"
        :key="h"
        layout
        :class="`row-cell ${
          (ref.status === 'offered' || ref.status === 'reoffered') &&
          ref[header.type].label === 'address'
            ? 'large-cell'
            : `xs${headers[h].size}`
        } ${headers[h].type === 'vehicles' ? 'row-cell--vehicles' : ``}`"
      >
        <v-spacer
          v-if="header.type === 'bar'"
          :class="`${ref[header.type].value}`"
        />

        <v-layout v-if="ref[header.type].type === 'display'" column>
          <div
            style="display: flex; align-items: center"
            :style="
              ref.status === 'Offered' || ref.status === 'Reoffered'
                ? `line-height: 2;`
                : ''
            "
            :class="ref[header.type].class ? ref[header.type].class : ''"
          >
            <div
              v-if="
                header.type === 'managedId' &&
                referralHasRequestedCancellation(ref[header.type].value)
              "
              class="red-dot"
            ></div>
            {{ ref[header.type].value }}
            <div
              v-if="
                header.type === 'managedId' &&
                ref.status === 'Accepted' &&
                hasReferralEditPermission
              "
              class="edit-referral-button"
              @click="openEditReferralSidebar(ref)"
            >
              Edit Referral
            </div>
          </div>

          <v-layout v-if="h === 0" column>
            <div
              v-if="ref.referral.offerNotificationSentDatetime"
              style="padding-top: 10px; font-size: 12px"
            >
              <b>
                {{
                  `${
                    ref.status === 'Reoffered' ||
                    referralReoffered[ref.referral.reservationId]
                      ? 'Re-'
                      : ''
                  }Offered:`
                }}
              </b>
              {{ userZonedTime(ref.referral.offerNotificationSentDatetime) }}
            </div>
            <template
              v-if="ref.status === 'Accepted' || ref.status === 'Confirmed'"
            >
              <div style="padding-top: 5px; font-size: 12px">
                <b>
                  {{
                    `${
                      referralReoffered[ref.referral.reservationId] ? 'Re-' : ''
                    }Accepted:`
                  }}
                </b>
                <template v-if="ref.referral.acceptedTime">
                  {{ userZonedTime(ref.referral.acceptedTime) }}
                </template>
              </div>
            </template>
            <div style="padding-top: 5px; font-size: 12px">
              <b>Last Viewed:</b>
              <template v-if="ref.referral.operatorViewedTime">
                {{ userZonedTime(ref.referral.operatorViewedTime) }}
              </template>
              <template v-if="ref.referral.lastViewedByName">
                <br />
                by {{ ref.referral.lastViewedByName }}
              </template>
            </div>

            <template
              v-if="ref.status === 'Offered' || ref.status === 'Reoffered'"
            >
              <div
                v-if="
                  hasValidExpiration(ref.referral.referralAcceptanceExpiration)
                "
                style="padding-top: 5px; font-size: 12px"
              >
                <b>Expires:</b>
                {{ userZonedTime(ref.referral.referralAcceptanceExpiration) }}
              </div>
              <v-btn
                :id="`${id}-reservation-referrals-button-mark-accept`"
                large
                color="success"
                :disabled="!active"
                @click="mark(true, ref.reservationId)"
              >
                Accept
              </v-btn>
              <v-btn
                v-if="isReferralRejectionReasonV2Enabled"
                :id="`${id}-referral-reject-button`"
                :loading="loading"
                large
                color="error"
                :disabled="!active"
                @click="openRejectReferralSidebar(ref)"
              >
                Reject
              </v-btn>
              <ReferralRejectDialog
                v-else
                :id="`${id}-reservation-referrals-button-mark-reject`"
                large
                :active="active"
                @reject="
                  (reasonType, notes) =>
                    mark(false, ref.reservationId, reasonType, notes)
                "
              />
            </template>

            <template
              v-if="ref.referral.operatorCancellationStatusKey === 'cancelled'"
            >
              <div style="padding-top: 5px; font-size: 12px">
                <b>Cancelled:</b>
                <template>
                  {{
                    userZonedTime(ref.referral.rejectedTime) ||
                    userZonedTime(ref.referral.cancelledOn)
                  }}
                </template>
                <br />
              </div>
            </template>
            <template v-else-if="ref.status === 'Rejected'">
              <div
                style="padding-top: 5px; font-size: 12px"
                :style="`color: ${$cr.theme.error}`"
              >
                <b>Rejected:</b>
                <template v-if="ref.referral.rejectedTime">
                  {{ userZonedTime(ref.referral.rejectedTime) }}
                </template>
              </div>
            </template>
            <template v-if="ref.status === 'Accepted'">
              <v-btn
                :id="`${id}-reservation-referrals-button-mark-unassign`"
                large
                color="error"
                :disabled="!active"
                style="margin-top: 10px"
                @click="unassignReferral(ref.referral)"
              >
                Unassign
              </v-btn>
            </template>
          </v-layout>
        </v-layout>

        <v-layout v-if="ref[header.type].label === 'passengers'" column>
          <div>
            {{ models[ref.id].referralPassengerCount }}
          </div>
        </v-layout>

        <v-layout
          v-if="ref[header.type].label === 'contact'"
          column
          class="mh-contact"
        >
          <v-flex>
            <v-layout row style="align-items: center">
              {{ ref['contact'].value[0] }}
              <v-tooltip
                v-if="
                  isAvailabilityV3Enabled &&
                  (companyConflict[ref.referral.companyId] === 'partial' ||
                    companyConflict[ref.referral.companyId] === 'complete')
                "
                top
                max-width="180"
              >
                <template #activator="{ on }">
                  <div class="margin-l-1 padding-t-2" v-on="on">
                    <CRIcon
                      view-box="0 0 24 24"
                      :color="
                        companyConflict[ref.referral.companyId] === 'partial'
                          ? 'yellow'
                          : 'red'
                      "
                      width="20"
                      height="20"
                    >
                      conflict
                    </CRIcon>
                  </div>
                </template>
                <span>
                  <p class="margin-y-0" style="text-align: center">
                    {{
                      availabilityTooltipText(
                        companyConflict[ref.referral.companyId]
                      )
                    }}
                  </p>
                </span>
              </v-tooltip>
            </v-layout>
          </v-flex>
          <v-flex>
            {{
              isEmpty(ref['contact'].value[1])
                ? ''
                : `${ref['contact'].value[1]}, `
            }}{{ ref['contact'].value[2] }}
          </v-flex>
          <v-flex class="contact-detail">
            {{ phoneFormatFilter(ref['contact'].value[3]) }}
          </v-flex>
          <v-flex class="contact-detail">
            {{ ref['contact'].value[4] }}
          </v-flex>
          <v-flex style="display: flex">
            <v-chip
              :color="$cr.theme.grayLight"
              label
              disabled
              style="right: 5px"
            >
              {{ ref['contact'].value[5] }}
            </v-chip>
            <v-chip
              :color="$cr.theme.grayLight"
              label
              disabled
              style="right: 5px"
            >
              {{ ref['contact'].value[6] }}
            </v-chip>
            <v-chip
              :color="$cr.theme.grayLight"
              label
              disabled
              style="right: 5px"
            >
              {{ ref['contact'].value[7] }}
            </v-chip>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="
            (ref.status === 'Accepted' || ref.status === 'Confirmed') &&
            ref[header.type].label !== 'passengers' &&
            h !== 0
          "
          column
          justify-start
        >
          <v-layout
            v-for="(group, gi) in models[ref.id].model"
            :key="`trip-vehicle-group-${gi}`"
            column
          >
            <h6
              v-if="
                (ref[header.type].type === 'vehicles' ||
                  ref[header.type].type === 'drivers') &&
                group.name
              "
              class="h-24"
            >
              {{ ref[header.type].type === 'vehicles' ? group.name : '' }}
            </h6>
            <v-layout
              v-for="(vehicleAssignment, vi) in group.vehicleAssignments"
              :key="`broker-assignment-input-row-${vi}`"
              row
              align-start
            >
              <template v-if="ref[header.type].type === 'vehicles'">
                <v-tooltip bottom left>
                  <template #activator="{ on }">
                    <CRTextField
                      :id="`reservation-referral-input-vehicle-${vi}`"
                      id-on-wrapper
                      :label="vehicleAssignment.vehicleTypeLabel || 'Vehicle'"
                      readonly
                      :background-color="$cr.theme.inputBackgroundGray"
                      :error="
                        !vehicleAssignment.vehicle.name ||
                        isUnassignedVehicleAssignment(vehicleAssignment)
                      "
                      :value="
                        vehicleAssignment.vehicle.name || 'Vehicle Unassigned'
                      "
                      append-icon="vehicles"
                      append-icon-viewbox="0 0 32 32"
                      @click.native="handleInputClick(ref.id)"
                      v-on="on"
                    />
                  </template>
                  <span v-if="!!vehicleAssignment.vehicle.name">
                    {{ vehicleAssignment.vehicle.name }}
                  </span>
                  <span v-else>Vehicle Unassigned</span>
                </v-tooltip>
              </template>
              <template v-if="ref[header.type].type === 'drivers'">
                <v-layout column>
                  <v-layout
                    v-for="(driverAssignment,
                    di) in vehicleAssignment.driverAssignments"
                    :key="`driver-assignment-input-${vi}-${di}`"
                    row
                    class="provider-assignment-input"
                  >
                    <v-tooltip bottom left>
                      <template #activator="{ on }">
                        <CRTextField
                          :id="`reservation-referral-input-driver-${vi}-${di}`"
                          id-on-wrapper
                          label="Drivers"
                          :value="
                            driverAssignment.driver.name || 'Driver Unassigned'
                          "
                          :error="!driverAssignment.driver.name"
                          readonly
                          :background-color="$cr.theme.inputBackgroundGray"
                          append-icon="drivers"
                          append-icon-viewbox="0 0 32 32"
                          v-on="on"
                          @click.native="handleInputClick(ref.id)"
                        />
                      </template>
                      <span v-if="!!driverAssignment.driver.name">
                        {{ driverAssignment.driver.name }}
                        <br />
                        {{ getDriverPhoneNumber(driverAssignment.driver) }}
                      </span>
                      <span v-else>Driver Unassigned</span>
                    </v-tooltip>
                    <v-tooltip v-if="driverAssignment.userId" right>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <CRIcon
                            class="copy-icon"
                            :view-box="`0 0 18 18`"
                            :width="40"
                            color="primary"
                            @click.native="callCopy(ref.id, driverAssignment.userId)"
                          >
                            driver_info
                          </CRIcon>
                        </div>
                      </template>
                      <v-layout>
                        <span>Copy Driver Info</span>
                      </v-layout>
                    </v-tooltip>
                    <div v-else style="min-width: 40px; min-height: 40px"></div>
                  </v-layout>
                </v-layout>
              </template>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-progress-linear
      v-if="loadingCall"
      :indeterminate="true"
      style="height: 2px; margin: 0px"
    />
    <v-layout row class="total-row" justify-space-between full-width padded>
      <v-flex xs5 text-xs-left style="padding-left: 12px">Total</v-flex>
      <v-spacer />
      <v-flex xs1 text-xs-center>
        <span
          style="
            position: relative;
            right: 20px;
            font-size: 1vw;
            text-align: right;
          "
        >
          {{ currencyFilter(referralPaymentTotal()) }}
        </span>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { DateTime } from 'luxon'
import op from 'simple-object-path'
import { currencyFilter } from '@/utils/currency'
import { capitalize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'
import { copyDriverInfo } from '@/utils/driver'
import availability from '@/services/availability'
import customerAccounts from '@/services/customerAccounts'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import drivers from '@/services/drivers'
import { authComputed } from '@/state/helpers'
import {
  buildModel,
  isUnassignedVehicleAssignment,
} from '@/utils/tripAssignments'
import { EventBus } from '@/utils/event-bus'
import uuid from 'uuid'
import ReferralRejectDialog from '../components/ReferralRejectDialog.vue'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'
import { mapGetters } from 'vuex'
import { ProviderConflict } from '@/utils/enum'
import { isEmpty } from '@/utils/string'

const OFFER_EDITED_REFERRAL_ACTION_TYPE_ID = 66

export default {
  components: {
    ReferralRejectDialog,
  },
  props: {
    distance: { type: Number, default: null },
    drivingTime: { type: Number, default: null },
    driverCount: { type: Number, default: null },
    requiredVehicles: { type: Array, default: () => [] },
    passengerCount: { type: Number, default: null },
    reservationId: { type: Number, default: null },
    companyId: { type: Number, default: null },
    active: { type: Boolean, default: false },
    referredTo: { type: Array, default: () => [] },
    amount: { type: Number, default: 0 },
    updateReservation: { type: Function, default: () => {} },
    assignedVehicles: { type: Array, default: () => [] },
    assignedDrivers: { type: Array, default: () => [] },
    customerAccount: { type: Object, default: () => {} },
    id: { type: String, default: null },
    tripId: { type: Number, default: null },
    stops: { type: Object, default: () => {} },
    managedId: { type: String, default: null },
  },
  data() {
    return {
      referrals: [],
      displayProps: [],
      models: {},
      headers: [
        { size: 3, text: 'ID', type: 'managedId', class: 'bold' },
        { size: 2, text: 'Company', type: 'contact' },
        { size: 1, text: 'Passengers', type: 'passengers' },
        { size: 3, text: 'Vehicles', type: 'vehicles' },
        { size: 3, text: 'Drivers', type: 'drivers' },
        { size: 1, text: 'Amount', type: 'referralTotal' },
      ],
      refToDisplayRoute: {
        managedId: { type: 'display', path: 'managedId' },
        passengers: { type: 'passengers', path: 'referralPassengerCount' },
        contact: {
          type: 'column',
          path: [
            ['companyName'],
            ['address/street1', 'address/street2'],
            ['address/completeAddress', 'address/postalCode'],
            ['companyPhone'],
            ['companyEmail'],
            ['partnerType'],
            ['onboardingStatusType'],
            ['tierLabel'],
          ],
        },
        vehicles: { type: 'vehicles', arr: 'requiredVehicles' },
        drivers: { type: 'drivers', path: 'requiredDrivers' },
        referralTotal: {
          type: 'display',
          path: 'referralAmount',
          align: 'non-aligned',
        },
      },
      loadingVehicles: false,
      loadingDrivers: false,
      vehiclesList: [],
      vehicleAssignments: [],
      loadingCall: false,
      companyConflict: {},
      referralReoffered: {},
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
      isAvailabilityV3Enabled: 'featureToggles/isAvailabilityV3Enabled',
    }),
    endDatetime() {
      const stops = [...this.stops].reverse()
      return stops.find((s) => s.dropoffDatetime != null)?.dropoffDatetime
    },
    hasReferralEditPermission() {
      return this.$store.getters['auth/hasPermission']('canEditReferrals')
    },
  },
  watch: {
    async referredTo(newVal, oldVal) {
      this.referrals = newVal

      const referralReservationIds = this.referrals.map((r) => r.reservationId)
      const vehicleAssignmentsResponse = await getTripAssignmentsForReservation(
        {
          reservationIds: referralReservationIds,
        }
      )
      this.vehicleAssignments =
        vehicleAssignmentsResponse.data.vehicleAssignments

      this.models = await this.setModels(this.referrals || [], {})
      this.displayProps = (this.referrals || []).map(
        this.mapReferralToDisplayProps(
          this.headers,
          this.refToDisplayRoute,
          this
        )
      )
      this.oldModels = JSON.parse(JSON.stringify(this.models))
      this.force()
    },
  },
  mounted() {
    this.refresh()
    EventBus.$on('refresh-reservation-detail', async () => {
      await this.refresh()
      this.$store.dispatch('app/closeDialog')
    })
  },
  methods: {
    op,
    currencyFilter,
    phoneFormatFilter,
    isUnassignedVehicleAssignment,
    getDriverPhoneNumber,
    isEmpty,
    userZonedTime(time) {
      if (!time) {
        return ''
      }
      return DateTime.fromISO(time, {
        zone:
          this.currentUser?.timeZone ||
          this.currentUser?.company?.address?.timeZone,
      }).toFormat('MM/dd/yyyy t ZZZZ')
    },
    hasValidExpiration(time) {
      if (!time) {
        return false
      }
      return (
        DateTime.fromISO(time, {
          zone:
            this.currentUser?.timeZone ||
            this.currentUser?.company?.address?.timeZone,
        }).diffNow().milliseconds > 0
      )
    },
    openNewTab(route, query) {
      const path = { name: route }
      if (query) {
        path.query = query
      }
      const routeData = this.$router.resolve(path)
      window.open(routeData.href, '_blank')
    },
    async getReferralReoffers() {
      for (const referral of this.referrals) {
        const params = { reservationId: referral.reservationId }
        const response = await this.$store.dispatch(
          'reservations/reservationAuditTVByReservationId',
          params
        )
        const audits = response.data.resultList
        if (
          audits.some(
            (audit) =>
              audit.actionTypeId === OFFER_EDITED_REFERRAL_ACTION_TYPE_ID
          )
        ) {
          this.referralReoffered[referral.reservationId] = true
        } else {
          this.referralReoffered[referral.reservationId] = false
        }
      }
    },
    async refresh() {
      this.loadingCall = true
      try {
        this.referrals = this.referredTo
        const referralReservationIds = this.referrals.map(
          (r) => r.reservationId
        )
        const vehicleAssignmentsResponse = await getTripAssignmentsForReservation(
          {
            reservationIds: referralReservationIds,
          }
        )
        this.vehicleAssignments =
          vehicleAssignmentsResponse.data.vehicleAssignments

        this.models = await this.setModels(this.referrals || [], {})
        this.displayProps = (this.referrals || []).map(
          this.mapReferralToDisplayProps(
            this.headers,
            this.refToDisplayRoute,
            this
          )
        )

        if (this.isAvailabilityV3Enabled) {
          await Promise.all(
            this.referrals.map(async (referral) => {
              const companyId = referral.companyId
              const reservationId = referral.reservationId
              const requiredVehicleTypeIds = referral.requiredVehicles.map(
                (v) => v.vehicleType.id
              )
              const affiliateVehicles = this.models[reservationId].vehicleList

              const params = {
                payload: {
                  startDatetime: DateTime.fromISO(this.$attrs.startDate),
                  endDatetime: DateTime.fromISO(
                    this.endDatetime || this.$attrs.startDate
                  ).endOf('day'),
                  vehicleIds: affiliateVehicles.map((v) => v.vehicleId),
                  vehicleTypeIds: requiredVehicleTypeIds,
                  companyId,
                },
              }
              const promises = [
                availability.getCompanyBlocks(params),
                availability.getVehicleTypeBlocks(params),
                availability.getVehicleBlocks(params),
              ]
              const responses = await Promise.all(promises)
              const companyBlocks =
                responses?.[0]?.data?.companyAvailabilityBlocks
              const vehicleTypeBlocks =
                responses?.[1]?.data?.vehicleTypeAvailabilityBlocks
              const vehicleBlocks =
                responses?.[2]?.data?.vehicleAvailabilityBlocks

              if (companyBlocks?.length > 0) {
                this.companyConflict[companyId] = ProviderConflict.Complete
              } else {
                const dispatchParams = {
                  payload: {
                    from: DateTime.fromISO(this.$attrs.startDate),
                    to: DateTime.fromISO(this.endDatetime),
                    companyId,
                  },
                }
                const dispatchResult = await this.$store.dispatch(
                  'dispatch/getDispatchDataAsBroker',
                  dispatchParams
                )
                const assignedOrOfferedReservations = dispatchResult?.data?.reservations?.filter(
                  (r) => r.reservationId != reservationId
                )
                const vehicleConflictCounts = {
                  none: 0,
                  partial: 0,
                  complete: 0,
                }
                for (const referralRequiredVehicle of referral.requiredVehicles) {
                  if (
                    vehicleTypeBlocks.some(
                      (b) =>
                        b.vehicleTypeId ===
                        referralRequiredVehicle.vehicleType.id
                    )
                  ) {
                    vehicleConflictCounts[ProviderConflict.Complete]++
                    continue
                  }
                  let count = affiliateVehicles.filter(
                    (v) =>
                      v.vehicleTypeId === referralRequiredVehicle.vehicleType.id
                  ).length
                  const blockedOutVehicleCount = vehicleBlocks.filter(
                    (vb) =>
                      vb.vehicleTypeId ===
                      referralRequiredVehicle.vehicleType.id
                  ).length
                  count -= blockedOutVehicleCount
                  for (const reservation of assignedOrOfferedReservations) {
                    for (const assignedOrOfferedVehicle of reservation.requiredVehicles) {
                      if (
                        assignedOrOfferedVehicle.vehicleType.id ===
                        referralRequiredVehicle.vehicleType.id
                      ) {
                        count -= assignedOrOfferedVehicle.quantity
                      }
                    }
                  }
                  if (count <= 0) {
                    vehicleConflictCounts[ProviderConflict.Complete]++
                  } else if (count < referralRequiredVehicle.quantity) {
                    vehicleConflictCounts[ProviderConflict.Partial]++
                  } else {
                    vehicleConflictCounts[ProviderConflict.None]++
                  }
                }
                if (
                  vehicleConflictCounts[ProviderConflict.Partial] > 0 ||
                  (vehicleConflictCounts[ProviderConflict.None] > 0 &&
                    vehicleConflictCounts[ProviderConflict.Complete] > 0)
                ) {
                  this.companyConflict[companyId] = ProviderConflict.Partial
                } else if (
                  vehicleConflictCounts[ProviderConflict.Complete] > 0
                ) {
                  this.companyConflict[companyId] = ProviderConflict.Complete
                } else {
                  this.companyConflict[companyId] = ProviderConflict.None
                }
              }
            })
          )
        }
        await this.getReferralReoffers()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loadingCall = false
      }
    },
    force() {
      this.$forceUpdate()
    },
    availabilityTooltipText(status) {
      let res = 'This provider has a conflict for this pickup date.'
      if (status === ProviderConflict.Partial) {
        res +=
          ' This provider has less vehicles available than the trip requires.'
      } else if (status === ProviderConflict.Complete) {
        res += ' There are no available vehicles.'
      }
      return res
    },
    referralPaymentTotal() {
      return this.referrals.reduce((acc, ref) => {
        if (ref.referralStatus === 'reoffered') {
          acc += ref.originalAmount
        } else if (ref.referralStatus !== 'rejected') {
          acc += ref.referralAmount
        }
        return acc
      }, 0)
    },
    async handleInputClick(reservationId) {
      let model = this.models[reservationId]
      let referral = this.referredTo.find(
        (r) => r.reservationId === reservationId
      )
      if (!model || !referral) return

      let { vehicleAssignments, vehicles, driverList, vehicleList } = model
      let stops = this.stops

      let trip = {
        vehicles,
        stops,
        tripId: this.tripId,
      }

      let reservation = { ...referral, vehicleAssignments, trip }
      const component = () => import('./DispatchReservationSidebar.vue')

      const allDrivers = driverList.map((driver) => ({
        ...driver,
        name: `${driver.firstName} ${driver.lastName}`,
      }))
      const allVehicles = vehicleList.map((vehicle) => ({
        ...vehicle,
        name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
      }))

      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          title: 'Add Assignments',
          parentReservationId: this.reservationId,
          reservationId: reservation.reservationId,
          managedId: reservation.managedId,
          referral: reservation,
          isAssignmentsOnly: true,
          vehicles: allVehicles,
          drivers: allDrivers,
          garages: [],
          companyId: this.activeReferralData?.companyId,
        },
      })
    },
    isCompleteRefVehicles(refId, vehicleType) {
      const required = this.referrals
        .find((r) => r.reservationId === refId)
        .requiredVehicles.find((v) => v.vehicleType.label === vehicleType)
        .quantity

      const assigned = Object.keys(
        this.models[refId].vehicles[vehicleType]
      ).reduce((total, vid) => {
        if (this.models[refId].vehicles[vehicleType][vid].assignment) {
          total++
        }
        return total
      }, 0)
      return assigned >= required ? 'complete' : 'incomplete'
    },
    async unassignReferral(referral) {
      if (referral) {
        const component = () =>
          import('@/components/UnassignReferralSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            reservation: { managedId: this.managedId },
            referral: referral,
            title: 'Unassign Referral',
          },
          component,
        })
      }
    },
    removeVehicle(refId, vehicleType, id) {
      delete this.models[refId].vehicles[vehicleType][id]
      const target = this.displayProps
        .find((ref) => ref.id === refId)
        .vehicles.arr.find((vehicle) => vehicle.label === vehicleType)

      target.quantity--
      target.total = target.total.filter((vehicle) => vehicle.id !== id)

      this.$forceUpdate()
    },
    checkQuantity(root, data, refId, type, vehicleType) {
      if (root === 'vehicles') {
        return Object.keys(data).reduce((acc, model) => {
          acc =
            acc &&
            Boolean(this.models[refId].vehicles[vehicleType][model].assignment)
          return acc
        }, Object.keys(this.models[refId].vehicles[vehicleType]).length > 0)
          ? `assignment__${type} --assigned`
          : `assignment__${type} --unassigned`
      }
      if (root === 'drivers') {
        return Object.keys(this.models[refId].drivers).reduce(
          (acc, model, index) => {
            const assigned = Boolean(this.models[refId].drivers[index])
            if (assigned) {
              acc.count++
            }
            if (
              acc.count >=
              this.displayProps.find((p) => p.id === parseInt(refId, 10))
                .drivers.value
            ) {
              acc.fullyAssigned = true
            }
            return acc
          },
          { count: 0, fullyAssigned: false }
        ).fullyAssigned
          ? `assignment__${type} --assigned`
          : `assignment__${type} --unassigned`
      }

      return ''
    },
    filterDriverOrVehicles(item, queryText) {
      if (!queryText) {
        return null
      }

      return (
        item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      )
    },
    mapReferralToDisplayProps(ordering, mappings, context) {
      return function returnDisplay(referral, index) {
        return ordering.reduce(
          (acc, order) => {
            const mapping = mappings[order.type]

            const mappedObj = {
              type: mapping.type,
              label: order.type,
              text: order.text,
              class: order.class,
              display: mapping.display,
              align: mapping.align,
              value: op(referral, mappings[order.type].path || ''),
            }

            if (Array.isArray(mappings[order.type].path)) {
              mappedObj.value = mappings[order.type].path.map((path) => {
                if (Array.isArray(path)) {
                  return path
                    .reduce((inner, subpath) => {
                      inner +=
                        (op(referral, subpath) ? op(referral, subpath) : '') +
                        ' '
                      return inner
                    }, '')
                    .trim()
                }

                return op(referral, path)
              })
            }
            if (order.type === 'drivers') {
              mappedObj.arr = [
                {
                  total: new Array(
                    context.models[referral.reservationId].drivers.length
                  ),
                },
              ]
              mappedObj.value = referral.driverCount
            }

            if (order.type === 'referralTotal') {
              if (referral.referralStatus === 'rejected') {
                mappedObj.class = 'inner-ref-total-rejected'
                mappedObj.value =
                  referral.operatorReferralAmount | referral.referralAmount
                mappedObj.value = currencyFilter(mappedObj.value)
              } else if (referral.referralStatus === 'reoffered') {
                mappedObj.class = 'inner-ref-total'
                mappedObj.value = referral.originalAmount
                mappedObj.value = currencyFilter(mappedObj.value)
              } else {
                mappedObj.class = 'inner-ref-total'
                mappedObj.value = currencyFilter(mappedObj.value)
              }
            }

            acc[order.type] = mappedObj

            return acc
          },
          {
            id: referral.reservationId,
            reservationId: referral.reservationId,
            status: capitalize(referral.referralStatus),
            referral,
          }
        )
      }
    },
    async getVehicleList(companyId) {
      const vehicleData = await this.$store.dispatch(
        'vehicles/getAllVehiclesForCompany',
        {
          page: 1,
          pageSize: -1,
          companyId,
        }
      )
      return vehicleData?.data?.resultList
    },
    async getDriverList(companyId) {
      const driverData = await drivers.getAllDriversForCompany({
        page: 1,
        pageSize: -1,
        companyId,
      })
      return driverData?.data?.resultList
    },
    driverContent(refId, model) {
      if (!model) {
        return []
      }

      const user = this.models[refId].driverList.find(
        (person) => person.userId === model
      )

      return [user.phoneNumber, user.email]
    },
    callCopy(refId, driverId) {
      this.$store.dispatch(
        'app/showAlert',
        copyDriverInfo(driverId, this.models[refId].driverList)
      )
    },
    async setModels(referrals, modelObject) {
      const reduceReferrals = async function (ref, cb, seed) {
        for (let i = 0; i < ref.length; i++) {
          seed = await cb(seed, ref[i])
        }
        return seed
      }
      return reduceReferrals(
        referrals,
        async (acc, referral) => {
          const referralId = referral.reservationId
          const reqVehicles = referral.requiredVehicles
          const reqDrivers = referral.requiredDrivers
          const vehicleAssignments = this.vehicleAssignments.filter(
            (v) => v.reservationId === referralId
          )
          const tripVehicleGroups = referral.tripVehicleGroups
          acc[referralId] = {}

          if (!referral.requiredVehicles) {
            return acc
          }

          acc[referralId].vehicles = reqVehicles
          acc[referralId].drivers = []
          acc[referralId].status = {}

          acc[referralId].vehicleList = await this.getVehicleList(
            referral.companyId
          )
          acc[referralId].driverList = await this.getDriverList(
            referral.companyId
          )
          acc[referralId].amount = referral.referralAmount

          acc[referralId].referralPassengerCount =
            referral.referralPassengerCount || 0

          acc[referralId].vehicleAssignments = vehicleAssignments

          let model = buildModel(
            vehicleAssignments,
            reqVehicles,
            reqDrivers,
            tripVehicleGroups
          )
          acc[referralId].model = model

          return acc
        },
        modelObject
      )
    },
    async saveAssignments() {
      const vehicleArray = []
      const driverArray = []

      this.loadingCall = true
      try {
        Object.keys(this.models).forEach((referralId) => {
          const refId = parseInt(referralId, 10)

          Object.keys(this.models[refId].vehicles).forEach((vehicleType) => {
            Object.keys(this.models[refId].vehicles[vehicleType]).forEach(
              (id) => {
                if (this.models[refId].vehicles[vehicleType][id].assignment) {
                  vehicleArray.push({
                    vehicleId: this.models[refId].vehicles[vehicleType][id]
                      .assignment,
                  })
                }
              }
            )
          })

          this.models[refId].drivers.forEach((driver) => {
            if (
              !driver ||
              driver === null ||
              (driver && driver.userId === null)
            ) {
              return
            }

            driverArray.push({ driverId: driver })
          })
        })

        await this.$store.dispatch('reservations/assignDrivers', {
          reservationId: this.reservationId,
          payload: {
            drivers: driverArray,
          },
        })
        await this.$store.dispatch('reservations/assignVehicles', {
          reservationId: this.reservationId,
          payload: {
            vehicles: vehicleArray,
          },
        })
      } catch (e) {
        this.loadingCall = false
        throw new Error(e)
      }

      this.loadingCall = false
    },
    async changeAmount(ref) {
      this.loadingCall = true
      try {
        await this.$store.dispatch('reservations/changeReferralAmount', {
          payload: {
            referrals: [{ id: ref.id, newTotal: this.models[ref.id].amount }],
          },
          reservationId: this.reservationId,
        })
      } catch (e) {
        throw new Error(e)
      }

      this.loadingCall = false
    },
    async mark(status, referralId, reasonType, notes) {
      this.loadingCall = true
      const params = {
        reservationId: referralId,
        referralId,
        companyId: this.companyId,
        refreshReservationId: this.reservationId,
        body: {
          referralRejectionReasonTypeId: reasonType,
          notes: notes,
        },
      }
      if (status) {
        let customerAccount = this.customerAccount
        if (customerAccount && customerAccount.enterpriseAccountId) {
          const { data } = await customerAccounts.getCustomerAccount(
            customerAccount.enterpriseAccountId
          )
          customerAccount = data
        }
        if (customerAccount?.useCustomReferralTerms) {
          this.$store.dispatch('app/showAlert', {
            type: 'error',
            message: `Provider Acceptance Required for ${customerAccount?.referralTermsName}. Contact the provider to accept this referral.`,
          })
          this.loadingCall = false
          return
        }
        params.reservationId = referralId
        await this.$store
          .dispatch('reservations/acceptReferral', params)
          .catch((error) => {
            throw new Error(error)
          })
      } else {
        await this.$store
          .dispatch('reservations/rejectReferral', params)
          .catch((error) => {
            throw new Error(error)
          })
      }
      this.loadingCall = false
    },
    referralHasRequestedCancellation(managedId) {
      if (!managedId) {
        return false
      }
      const foundReferral = this.referredTo.find(
        (referral) => referral.managedId === managedId
      )
      return foundReferral?.operatorCancellationStatusKey === 'requested'
    },
    openEditReferralSidebar(ref) {
      const component = () => import('./ReferralEditSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          referral: ref.referral,
          reservationId: this.reservationId,
          driverCount: this.driverCount,
          passengerCount: this.passengerCount,
          requiredVehicles: this.requiredVehicles,
          title: `Edit Referral`,
        },
      })
    },
    openRejectReferralSidebar(referral) {
      const component = () => import('./ReferralRejectSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          reservation: {
            managedId: this.managedId,
          },
          referral,
          title: `Reject Referral`,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.bold {
  font-weight: 600;
}

.red-dot {
  width: 10px;
  height: 10px;
  background: $error;
  margin-right: 10px;
  border-radius: 5px;
}

.edit-referral-button {
  cursor: pointer;
  color: $primary;
  margin-left: 15px !important;
  font-weight: 500;
}

.passenger-box::v-deep input {
  text-align: right;
}

.row-container {
  padding-top: 10px;
  border-right: 1px solid $gray-light;
  border-bottom: 1px solid $gray-light;
  border-left: 1px solid $gray-light;
}

.header-row {
  position: relative;
  align-items: center;
  height: 65px;
  color: grey;
  border: 1px solid $gray-light;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.row-cell {
  display: flex;
  padding: 14px 0 24px 24px;
  overflow-wrap: break-word;

  &--vehicles {
    align-items: flex-start;
  }
}

.copy-icon {
  margin-top: 32px !important;
  padding-left: 15px;
}

.assignment {
  &__circle {
    width: 30px;
    min-width: 30px;
    height: 30px;
    line-height: 2;
    color: $white;
    text-align: center;
    border-radius: 50%;
    &.--unassigned {
      background-color: $error;
    }
    &.--assigned {
      background-color: $success;
    }
  }

  &__text {
    line-height: 2;
  }
}

.driver::v-deep span {
  width: 80px;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-detail {
  color: $primary;
}

.mh-contact {
  display: block;
  min-height: 130px;
}

.mw-90 {
  width: 90%;
  margin-top: -10px;
  margin-bottom: 20px;
  margin-left: -5px;
  color: $white;
}

.center-align {
  align-items: center;
}

.total-row {
  height: 45px;
  font-size: 20px;
  line-height: 2;
  color: $white;
  text-align: center;
  background-color: $gray;
}

.complete {
  background-color: $green;
}

.incomplete {
  background-color: $red;
}

.add-new-vehicle {
  margin-bottom: 10%;
  font-size: 16px;
  color: $blue;
  cursor: pointer;
}

.trash {
  margin-left: 8%;
  font-size: 14px;
  line-height: 3;
  text-align: center;
  cursor: pointer;

  .v-icon {
    color: $blue;
  }
}

.driver-tool-tip {
  z-index: 768;
}

.vehicles {
  margin-right: 25%;
}

.mr-10 {
  margin-right: 10%;
}

.mr-20 {
  max-height: 30px;
  margin-right: 20%;
}

.amount-input::v-deep input {
  text-align: right;
}

button.save-button.v-btn {
  position: absolute;
  top: 7px;
  right: 0;
}

.p-11 {
  padding-top: 11%;
}

.ref-total {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1vw;
}

.mh-30 {
  max-height: 30px;
}

.inner-ref-total {
  font-size: 1vw;
}

.inner-ref-total-rejected {
  font-size: 1vw;
  text-decoration: line-through;
}
</style>
