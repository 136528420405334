<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4 v-tabs__container">
      <template v-for="component in reservationComponents">
        <v-tab
          v-show="shouldShowComponent(component) && isFeatureToggled(component)"
          :id="`reservation-tab-${component.text.toLowerCase()}`"
          :key="component.text"
          class="v-tabs__item"
        >
          <div
            v-if="component.text === 'Referrals' && hasCancellationRequest"
            class="red-dot"
          ></div>
          {{ component.text }}
        </v-tab>
      </template>
    </v-tabs>
    <keep-alive>
      <component
        :is="reservationComponents[selectedTab].value"
        :id="`${id}-reservation-component-selector`"
        v-bind="reservation"
        :customer-account="customerAccount"
        :payment-method-types="paymentMethodTypes"
        :balance-payment-methods="balancePaymentMethods"
        @refresh="$emit('refresh')"
      />
    </keep-alive>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { mapActions, mapGetters } from 'vuex'
import ReservationComments from '@/components/ReservationComments.vue'
import ReservationReferrals from '@/components/ReservationReferrals.vue'
import ReservationQuote from '@/components/ReservationQuote.vue'
import ReservationTickets from '@/components/ReservationTickets.vue'
import ReservationPayments from '@/components/ReservationPayments.vue'
import ReservationAudits from '@/components/ReservationAuditsTV.vue'
import ReservationReviews from '@/components/ReservationReviews.vue'
import ReservationTracking from '@/components/ReservationTracking.vue'
import ReservationNotifications from '@/components/ReservationNotifications.vue'
import QuoteFormTripSummary from '@/components/QuoteFormTripSummary.vue'
import {
  convertTripAddressDTOsToTripAddresses,
  convertTripVehicleGroupDTOsToTripVehicleGroups,
} from '@/utils/quoteUtils'

export default {
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    paymentMethodTypes: {
      type: Array,
      required: true,
    },
    balancePaymentMethods: {
      type: Array,
      required: true,
    },
    customerAccount: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 0,
      reservationComponents: [
        { text: 'Tracking', value: ReservationTracking },
        {
          text: 'Referrals',
          value: ReservationReferrals,
          brokersOnly: true,
          hideFromRA: true,
        },
        { text: 'Payments', value: ReservationPayments },
        { text: 'Quote', value: ReservationQuote, ownersOnly: true },
        { text: 'Comments', value: ReservationComments },
        { text: 'Tickets', value: ReservationTickets, ownersOnly: true },
        {
          text: 'Notifications',
          value: ReservationNotifications,
          marketplaceOnly: true,
        },
        { text: 'Reviews', value: ReservationReviews, ownersOnly: true },
        { text: 'Logs', value: ReservationAudits, hideFromRA: true },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    hasCancellationRequest() {
      const referrals = this.reservation.referredTo
      if (!referrals) {
        return
      }
      const operatorCancellationStatus = referrals.map(
        (ref) => ref.operatorCancellationStatusKey
      )
      return operatorCancellationStatus.includes('requested')
    },
  },
  watch: {
    reservation: {
      deep: true,
      immediate: true,
      handler() {
        if (
          this.isShuttleCreateQuoteEnabled &&
          this.reservation?.tripVehicleGroups?.length
        ) {
          this.initializeQuoteModule()
          this.addTripModule({})
          this.setTripAddressesModule({
            tripAddresses: convertTripAddressDTOsToTripAddresses(
              this.reservation.tripAddresses
            ),
          })
          this.setTripVehicleGroupsModule({
            tripVehicleGroups: convertTripVehicleGroupDTOsToTripVehicleGroups(
              this.reservation.tripVehicleGroups,
              this.reservation.stops
            ),
          })
          this.reservation.tripAddresses = convertTripAddressDTOsToTripAddresses(
            this.reservation.tripAddresses
          )
          this.reservation.tripVehicleGroups = convertTripVehicleGroupDTOsToTripVehicleGroups(
            this.reservation.tripVehicleGroups,
            this.reservation.stops
          )
          if (!this.reservationComponents.find((c) => c.text === 'Summary')) {
            this.reservationComponents.push({
              text: 'Summary',
              value: QuoteFormTripSummary,
            })
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      initializeQuoteModule: 'quoteForm/initialize',
      addTripModule: 'quoteForm/addTrip',
      setTripAddressesModule: 'quoteForm/setTripAddresses',
      setTripVehicleGroupsModule: 'quoteForm/setTripVehicleGroups',
    }),
    shouldShowComponent(component) {
      if (this.isBroker && component.providersOnly) {
        return false
      }
      if (!this.isBroker && component.brokersOnly) {
        return false
      }
      if (this.reservation.reservationType === 1 && component.ownersOnly) {
        return false
      }
      if (this.isRA && component.hideFromRA) {
        return false
      }
      if (!this.reservation?.isCharterUP && component.marketplaceOnly) {
        return false
      }
      // All exclusions passed
      return true
    },
    isFeatureToggled(component) {
      if (component.featureToggle) {
        const { getters = {} } = this.$store
        return getters[`featureToggles/${component.featureToggle}`]
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.red-dot {
  width: 10px;
  height: 10px;
  background: $error;
  margin: 2px 4px 0 0;
  border-radius: 5px;
}

.v-tabs {
  .v-tabs__wrapper {
    .v-tabs__container {
      flex-direction: row;
      justify-content: flex-start !important;
      margin-top: 50px;
      .v-tabs__div {
        &.v-tabs__item {
          font-size: 14px !important;
          font-weight: 600 !important;
          background-color: $gray-new;
          height: 44px;
          opacity: 1;
          border-radius: 6px 0 0 0;
          width: 101px;
          &:has(.v-tabs__item--active) {
            background-color: $gray-light !important;
            border-radius: 6px 6px 0 0 !important;
          }
        }
        &.v-tabs__item ~ .v-tabs__item {
          border-radius: 0 0 0 0;
          width: fit-content;
        }
      }
    }
  }
}

::v-deep .v-tabs {
  background-color: $gray-new;
  border-radius: 0 6px 0 0;
  margin-top: 50px;
  height: 44px;
  &__item {
    &--active {
      color: $blue-new !important;
    }
  }
  &__slider-wrapper {
    .v-tabs__slider.accent {
      background-color: $blue-new !important;
      border-color: $blue-new !important;
      margin-bottom: 4px;
    }
  }
}
</style>
