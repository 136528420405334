
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { baseUrl, environmentPrefix } from '../utils/env'
import auth from '@/services/auth'
import companies from '@/services/companies'
import ticket from '@/services/ticket'
import { getCancellationConfirmation } from '@/services/reservations'
import { getReservationItineraryAsString } from '@/utils/reservation'
import { Reservation, Ticket } from '@/models/dto'
import { MenuItem } from '@/models/MenuItem'
import { ReservationStatusKey, CancellationStatusKey } from '@/utils/enum'

@Component({})
export default class ReservationActionsMenu extends Vue {
  @Prop({ type: Object, default: null }) readonly reservation: Reservation
  @Prop({ type: Object, required: true }) readonly currentUserProfile: any
  @Prop({ type: Number, required: true }) readonly userId: number
  @Prop({ type: Array, default: () => [] }) readonly reasons: any[]

  selectedMenuItem: string = ''
  isActionInProgress: boolean = false
  actionMap: Record<string, any> = {}
  componentMap: Record<string, any> = {}
  pdfBaseURL: string = baseUrl('pdf')
  mostRecentCancelTicket: Ticket = null
  cancellationConfirmation: any = null
  isTicketCancellationStatusKnown: boolean = false
  ticketCancellationRequired: boolean = true

  @Watch('menuItems')
  onMenuItemsChange(): void {
    this.refreshActionAndComponentMaps()
    this.ticketCancel()
  }

  @Watch('reservation', { deep: true, immediate: true })
  onReservationChange(newValue: Reservation, oldValue: Reservation): void {
    if (!newValue) {
      return
    }

    if (newValue?.reservationId !== oldValue?.reservationId) {
      this.getMostRecentCancelTicket()
    }

    if (
      newValue?.cancellationStatusKey !== oldValue?.cancellationStatusKey ||
      newValue?.cancellationClassificationLabel !==
        oldValue?.cancellationClassificationLabel
    ) {
      this.getMostRecentCancelTicket()
    }
  }

  async mounted(): Promise<void> {
    this.refreshActionAndComponentMaps()
    this.ticketCancel()
    if (!this.reservation?.reservationId) {
      return
    }
    this.getMostRecentCancelTicket()
    this.getCancellationConfirmation()
  }

  get isActive(): boolean {
    return this.reservation?.active
  }

  get menuItemsDefinitions(): MenuItem[] {
    return [
      {
        value: 'mark-rejected',
        text: 'Mark Rejected',
        action: this.reject,
        onlyForStatus: 'offered',
      },
      {
        value: 'resend-confirmation',
        text: 'Resend Confirmation',
        action: 'reservations/resendConfirmation',
        onlyForType: 0,
      },
      {
        value: 'resend-receipt',
        text: 'Resend Receipt',
        action: 'reservations/resendReceipt',
        onlyForType: 0,
      },
      {
        value: 'start',
        text: 'Start',
        action: 'reservations/start',
        onlyForStatus: ReservationStatusKey.Upcoming,
      },
      {
        value: 'end',
        text: 'End',
        action: 'reservations/end',
        onlyForStatus: ReservationStatusKey.Started,
      },
      {
        value: 'cancel',
        text: 'Cancel',
        title: 'Start Cancellation',
        action: 'app/openSidebarDialog',
        component: () =>
          this.ticketCancellationRequired
            ? import('./ReservationFormCancel.vue')
            : import('./ReservationFormCancelNoFlag.vue'),
        onlyForType: 0,
        notForCancellationStatus: true,
      },
      {
        value: 'edit-cancel',
        text: 'Edit Cancellation',
        title: 'Edit Cancellation',
        action: 'app/openSidebarDialog',
        component: () => import('./ReservationEditCancellation.vue'),
        onlyForCancellationStatus: true,
      },
      {
        value: 'send-cancellation-form-email',
        text: `${
          this.reservation?.customerCancellationEmailSent ? 'Res' : 'S'
        }end Cancellation Form Email`,
        title: `${
          this.reservation?.customerCancellationEmailSent ? 'Res' : 'S'
        }end Cancellation Form Email`,
        action: 'app/openSidebarDialog',
        component: () => import('./SendCancellationStartEmail.vue'),
        onlyForStartedCancellationStatusKeyAndNotFormCompleted: true,
      },
      {
        value: 'reinstate',
        text: 'Reinstate Reservation',
        title: 'Reinstate Reservation',
        action: 'app/openSidebarDialog',
        component: () => import('./ReinstateReservationSidebar.vue'),
        onlyForStatus: ReservationStatusKey.Cancelled,
        hideOn: !this.showReinstateReservation,
      },
      {
        value: 'send-driver-info',
        text: 'Send Driver Info',
        title: 'Send Driver Information',
        action: 'app/openSidebarDialog',
        component: () => import('./ReservationSendDriverInfo.vue'),
        onlyForType: 0,
      },
      {
        value: 'edit-trip-name',
        text: 'Edit Trip Name',
        title: 'Edit Trip Name',
        action: 'app/openSidebarDialog',
        component: () => import('./EditTripNameSidebar.vue'),
        onlyForType: 0,
      },
      {
        value: 'edit-hotel-requirment',
        text: 'Driver Hotel Info',
        title: 'Driver Hotel Info',
        action: 'app/openSidebarDialog',
        data: {
          tripId: this.reservation?.tripId,
          hotelInformation: this.reservation?.hotelInformation,
        },
        component: () => import('./DriverHotelSidebar.vue'),
        onlyForType: 0,
      },
      {
        value: 'client-view',
        text: 'Client View',
        action: this.actionClientView,
        charterUpEnabledOnly: true,
      },
      {
        value: 'update-reservation-status',
        text: 'Update Reservation Status',
        action: 'app/openSidebarDialog',
        component: () => import('./ReservationStatusUpdate.vue'),
        onlyForType: 0,
      },
      {
        value: 'driver-sheet',
        text: 'Driver Sheet',
        providerOnly: true,
        action: this.driverSheetURI,
      },
      {
        value: 'send-itinerary-update',
        text: 'Send Itinerary Update',
        action: 'app/openSidebarDialog',
        component: () => import('./ReservationSendItineraryUpdate.vue'),
        onlyForType: 0,
      },
      {
        value: 'copy-full-itinerary',
        text: 'Copy Full Itinerary',
        action: this.copyFullItinerary,
      },
      {
        value: 'copy-tracking-link',
        text: 'Copy Tracking Link',
        action: this.copyTrackingLink,
      },
      {
        value: 'set-is-enterprise-false',
        text: 'Set Not Enterprise',
        action: 'reservations/setEnterpriseFalse',
        onlyForType: 0,
        hideOn: !this.reservation?.isEnterprise,
      },
      {
        value: 'set-is-enterprise-true',
        text: 'Set Enterprise',
        action: 'reservations/setEnterpriseTrue',
        onlyForType: 0,
        hideOn: this.reservation?.isEnterprise,
      },
      {
        value: 'sync-reservation',
        text: 'Sync Reservation',
        action: 'reservations/syncReservation',
      },
      {
        value: 'customer-login',
        text: 'Login As Customer',
        action: this.loginAsCustomer,
        hideOn: !this.canLoginAsCustomer,
      },
      {
        value: 'team-selection',
        text: 'Edit Ownership',
        action: 'app/openSidebarDialog',
        data: {
          reservationId: this.reservation?.reservationId,
          quoteId: this.reservation?.quoteId,
          existingProductClassificationId: this.reservation
            ?.productClassificationId,
          existingSourcingTeamClassificationId: this.reservation
            ?.sourcingTeamClassificationId,
          existingSupportTeamClassificationId: this.reservation
            ?.supportTeamClassificationId,
          existingTierId: this.reservation?.tier?.tierId,
        },
        component: () => import('./ReservationTeamSelection.vue'),
      },
    ]
  }

  get menuLabel(): string {
    return this.isActionInProgress ? 'In Progress' : 'Actions'
  }

  get menuItems(): MenuItem[] {
    return this.menuItemsDefinitions.filter((item: MenuItem) => {
      if (item.hideOn) {
        return false
      }
      if (this.reservation?.reservationType === 1 && item.onlyForType === 0) {
        return false
      }
      if (
        item.onlyForStatus &&
        item.onlyForStatus !== this.reservation?.reservationStatus
      ) {
        return false
      }
      if (
        item.notForStatus &&
        item.notForStatus === this.reservation?.reservationStatus
      ) {
        return false
      }
      if (
        item.notForCancellationStatus &&
        this.reservation?.cancellationStatusKey
      ) {
        return false
      }
      if (
        item.onlyForCancellationStatus &&
        !this.reservation?.cancellationStatusKey
      ) {
        return false
      }
      if (item.charterUpEnabledOnly) {
        if (!this.canOpenCharterUpQuote || !this.reservation?.isCharterUP) {
          return false
        }
      }
      if (item.onlyForStartedCancellationStatusKeyAndNotFormCompleted) {
        if (
          this.reservation?.cancellationStatusKey !== CancellationStatusKey.Started ||
          this.cancellationConfirmation != null
        ) {
          return false
        }
      }
      return true
    })
  }

  get canOpenCharterUpQuote(): boolean {
    const roles = this.currentUserProfile?.roles || []
    const charterUpRole = roles.find((r) => r.roleName === 'is_charterup_sales')
    const charterUpPermissions = charterUpRole?.permissions || []
    return !!charterUpPermissions.find(
      (p) => p.permissionName === 'canSendCharterUPQuote'
    )
  }

  get showReinstateReservation(): boolean {
    if (
      this.reservation?.cancellationStatusKey === CancellationStatusKey.Cancelled &&
      this.reservation?.reservationStatus === ReservationStatusKey.Cancelled
    ) {
      return true
    }
    return false
  }

  get canLoginAsCustomer(): boolean {
    const roles = this.currentUserProfile?.roles || []
    const role = roles.find((r) => r.roleName === 'can_login_as_customer')
    return !!role
  }

  async getMostRecentCancelTicket(): Promise<void> {
    const response = await ticket.getMostRecentCancellationTicket(
      this.reservation?.reservationId
    )
    this.mostRecentCancelTicket = response.data.ticket
    if (this.mostRecentCancelTicket) {
      const mid = this.menuItemsDefinitions.find(
        (mid) => mid.value === 'edit-cancel'
      )
      mid.title = `Edit ${this.mostRecentCancelTicket.title}`
    }
  }

  async getCancellationConfirmation(): Promise<void> {
    const cancellationConfirmationResult = await getCancellationConfirmation(
      this.reservation?.reservationId
    )
    this.cancellationConfirmation =
      cancellationConfirmationResult?.data?.cancellationConfirmation
  }

  refreshActionAndComponentMaps(): void {
    for (const item of this.menuItems) {
      this.actionMap[item.value] = item.action
      if (item.component) {
        this.componentMap[item.value] = item.component
      }
    }
  }

  async ticketCancel(): Promise<void> {
    if (!this.reservation?.companyId) {
      return
    }
    const companyResponse = await companies.getCompany(
      this.reservation?.companyId
    )
    this.ticketCancellationRequired =
      companyResponse?.data.company.ticketCancellationRequired
    this.isTicketCancellationStatusKnown = true
  }

  // TODO: May not need this anymore. Try seeing if we can just use the generic doAction below.
  async reject(): Promise<void> {
    const payload = {
      referralId: this.reservation?.referralId,
      companyId: this.reservation?.companyId,
      reservationId: this.reservation?.reservationId,
      refreshReservationId: this.reservation?.reservationId,
    }
    this.$nextTick(() => {
      this.selectedMenuItem = ''
    })

    await this.$store.dispatch('reservations/rejectReferral', payload)

    this.isActionInProgress = false
    this.$router.push({ name: 'reservations' })
  }

  async doMenuAction(
    menuKey: string,
    menuTitle: string = '',
    data: any = {}
  ): Promise<void> {
    this.isActionInProgress = true
    const action = this.actionMap[menuKey]
    const component = this.componentMap[menuKey]
    this.$nextTick(() => {
      this.selectedMenuItem = ''
    })

    if (typeof action === 'string') {
      await this.$store.dispatch(action, {
        reservationId:
          this.reservation?.parentReservationId ||
          this.reservation?.reservationId,
        data: {
          reservationId: this.reservation?.reservationId,
          managedId: this.reservation?.managedId,
          customerEmail: this.reservation?.customerEmail,
          tripContactEmail: this.reservation?.tripContactEmail,
          tripId: this.reservation?.tripId,
          title: menuTitle,
          referredTo: this.reservation?.referredTo,
          ticket: this.mostRecentCancelTicket,
          customerCancellationEmailSent: this.reservation
            ?.customerCancellationEmailSent,
          ...data,
        },
        component,
      })
    } else {
      action()
    }
    this.isActionInProgress = false
  }

  actionClientView(): void {
    this.isActionInProgress = true
    this.$router.push({
      name: 'charterup-reservation-detail',
      params: { id: this.reservation?.reservationId.toString() },
    })
    this.isActionInProgress = false
  }

  driverSheetURI(): void {
    if (
      !this.reservation?.journeys ||
      this.reservation?.journeys.length === 0
    ) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Vehicles and Drivers must be assigned first.',
      })
      return
    }
    window.open(
      `https://${this.pdfBaseURL}/pdf/driver-itinerary/${this.reservation?.hash}`,
      '_blank'
    )
  }

  async copyFullItinerary(): Promise<void> {
    const result = await getReservationItineraryAsString(this.$props, true)
    navigator.clipboard.writeText(result)
    this.$store.dispatch('app/showAlert', {
      type: 'success',
      message: 'Itinerary info copied to your clipboard',
    })
  }

  async copyTrackingLink(): Promise<void> {
    const url = `https://${environmentPrefix()}.charterup.com/livetracking/public/${
      this.reservation?.hash
    }`
    navigator.clipboard.writeText(url)
    this.$store.dispatch('app/showAlert', {
      type: 'success',
      message: 'Tracking link copied to your clipboard',
    })
  }

  async loginAsCustomer(): Promise<void> {
    if (!this.reservation?.customerId || !this.userId) {
      return
    }
    const payload = {
      userId: this.reservation?.customerId,
      delegatorId: this.userId,
    }
    try {
      const res = await auth.requestJwtLogin(payload)
      const redirectUrl = `https://${environmentPrefix()}.charterup.com/?jwt=${
        res.data.token
      }`
      window.open(redirectUrl, '_blank')
    } catch (e) {
      console.error(e)
    }
  }
}
