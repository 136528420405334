<template>
  <v-flex v-if="referredProviders.length > 1" shrink>
    <CRSelect
      v-model="currentProviderComputed"
      class="reservation-trip-assignment-selector mr-2"
      :items="referredProviders"
      item-text="computedName"
      flat
      solo
      color="$vuetify.theme.gray"
      background-color="$vuetify.theme.backgroundColor"
      shrink
      return-object
      @change="updateCurrentProvider"
    />
  </v-flex>
  <v-flex
    v-else-if="referredProviders.length === 1"
    shrink
    class="static-info-wrapper mb-2 d-flex align-center"
  >
    <p class="static-info">
      {{ referredProviders[0].computedName }}
    </p>
  </v-flex>
</template>

<script>
export default {
  props: {
    referredProviders: { type: Array, required: true },
    currentProvider: { type: Object, required: true },
  },
  emits: ['update-current-provider'],
  data() {
    return {}
  },
  computed: {
    currentProviderComputed: {
      get() {
        return this.currentProvider
      },
      set(newCurrentProvider) {
        this.updateCurrentProvider(newCurrentProvider)
      },
    },
  },
  methods: {
    updateCurrentProvider(newCurrentProvider) {
      this.$emit('update-current-provider', newCurrentProvider)
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-trip-assignment-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $gray;
  }

  .theme--light.v-select .v-select__selections {
    color: $gray !important;
    font-weight: 700;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
      background-color: $background-hover !important;

      input,
      select {
        width: 40px !important;
      }
    }
  }
}

.static-info-wrapper {
  height: 40px;
  background: $background-hover;
  border-radius: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:not(:first-child) {
    padding-left: 0px !important;
    margin-left: 7px;
  }
}

.static-info {
  max-width: 100%;
  height: fit-content;
  color: $gray;
  font-weight: bold;
  padding: 0 12px 0 12px;
  margin-bottom: 0px !important;
}
</style>
