import { render, staticRenderFns } from "./VehicleEtaColumn.vue?vue&type=template&id=dfa6e828&scoped=true"
import script from "./VehicleEtaColumn.vue?vue&type=script&lang=js"
export * from "./VehicleEtaColumn.vue?vue&type=script&lang=js"
import style0 from "./VehicleEtaColumn.vue?vue&type=style&index=0&id=dfa6e828&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa6e828",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})
