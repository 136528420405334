
import { DateTime } from 'luxon'
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { markTripModificationRequestComplete } from '@/services/reservations'

@Component({})
export default class TripModificationRequestToggle extends Vue {
  @Prop({ required: true, type: String }) readonly managedId: string
  @Prop({ type: Boolean, default: false }) readonly hasPendingModificationRequest: boolean
  @Prop({ type: String, default: '' }) readonly modificationRequestSubmittedOn: string

  toggleValue = false

  @Watch('hasPendingModificationRequest')
  onBalanceChanged(newValue: boolean) {
    this.toggleValue = newValue
  }

  mounted() {
    this.toggleValue = this.hasPendingModificationRequest
  }

  get toggleText(): string {
    return this.hasPendingModificationRequest ? 'Active change request' : 'No active change request'
  }

  get formattedModificationSubmittedOn(): string {
    const currentUser = this.$store.getters['auth/currentUser']
    return DateTime.fromISO(this.modificationRequestSubmittedOn, {
      zone: 'utc',
    })
      .setZone(currentUser.timeZone)
      .toFormat('MM/dd/yy - hh:mm a ZZZZ')
  }

  async handleCompleteModificationRequest(): Promise<void> {
    try {
      await markTripModificationRequestComplete(this.managedId)
    } catch (error) {
      this.toggleValue = this.hasPendingModificationRequest
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error marking request as completed. Please try again.',
      })
    }

    this.$emit('refresh')
  }

}
