<template>
  <div>
    <p v-if="reservationReview.customer">
      <b>Reviewing Customer:</b>
      <a @click="goToCustomerDetail">
        {{
          `${reservationReview.customer.firstName} ${reservationReview.customer.lastName}`
        }}
      </a>
    </p>
    <template v-if="reservationReview.rating">
      <p class="review-label"><b>Rating</b></p>
      <v-rating :value="reservationReview.rating" readonly dense size="20" />
      <p class="review-text">{{ reservationReview.review }}</p>
    </template>
    <template v-if="reservationReview.pickupPositive !== null">
      <p class="review-label"><b>Pickup</b></p>
      <CRIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        style="margin-right: 8px"
        :color="
          reservationReview.pickupPositive ? 'success' : 'grayMediumLight'
        "
      >
        thumb_up
      </CRIcon>
      <CRIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        :color="!reservationReview.pickupPositive ? 'error' : 'grayMediumLight'"
      >
        thumb_down
      </CRIcon>
      <p class="review-text">{{ reservationReview.pickupReview }}</p>
    </template>
    <template v-if="reservationReview.charterUpRating">
      <p class="review-label"><b>CharterUP</b></p>
      <v-rating
        :value="reservationReview.charterUpRating"
        readonly
        dense
        size="20"
      />
      <p class="review-text">{{ reservationReview.charterUpReview }}</p>
      <p class="review-label"><b>Operator</b></p>
      <v-rating
        :value="reservationReview.operatorRating"
        readonly
        dense
        size="20"
      />
      <p class="review-text">{{ reservationReview.operatorReview }}</p>
      <p class="review-label"><b>Vehicle</b></p>
      <v-rating
        :value="reservationReview.vehicleRating"
        readonly
        dense
        size="20"
      />
      <p class="review-text">{{ reservationReview.vehicleReview }}</p>
      <p class="review-label"><b>Driver</b></p>
      <v-rating
        :value="reservationReview.driverRating"
        readonly
        dense
        size="20"
      />
      <p class="review-text">{{ reservationReview.driverReview }}</p>
    </template>
    <template v-else>
      <h3>No review has been submitted yet</h3>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    reservationReview: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goToCustomerDetail() {
      let routeData = this.$router.resolve({
        name: 'customers.details',
        params: { id: this.reservationReview.customer.customerId },
      })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.review-label {
  margin-bottom: 0;
}
.review-text {
  max-width: 500px;
}
</style>
