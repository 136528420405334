<template>
  <div>
    <span
      v-if="parentSpotTime"
      style="display: flex"
      class="ml-3 spot-time parent"
    >
      [Spot Time
      {{ parentSpotTime | formatTime(spotTimezone) }}]
    </span>
    <span
      v-if="type.indexOf('Pickup') >= 0 && !parentSpotTime"
      class="spot-time"
      style="display: flex"
    >
      <v-dialog v-model="isSpotTimeModalOpen" width="500" persistent>
        <template #activator="{ on }">
          <div
            v-if="spotTime && activeSpot"
            style="display: flex; align-items: center"
          >
            <span
              :id="`${stopId}-reservation-itinerary-button-open-spot-time`"
              class="spot-time-button"
              v-on="on"
              @click="currentSpotTimeSelection = null"
            >
              [Spot Time
              {{ spotTime | formatTime(spotTimezone) }}]
            </span>
            <CRIcon
              view-box="0 0 24 24"
              width="18"
              height="18"
              class="icon-primary"
              @click.native="deleteSpotHandler"
            >
              close
            </CRIcon>
          </div>
          <span
            v-else-if="activeReservation"
            :id="`${stopId}-reservation-itinerary-button-add-spot-time`"
            class="spot-time-button"
            v-on="on"
            @click="currentSpotTimeSelection = 15"
          >
            Add Spot Time
          </span>
        </template>
        <v-card class="cr-modal">
          <v-card-title>
            <h2>Update Spot Times</h2>
          </v-card-title>
          <v-card-text>
            <v-layout padded align-center>
              <v-flex>
                <CRSelect
                  :id="`reservation-${stopId}-set-spot-minutes`"
                  v-model="currentSpotTimeSelection"
                  :items="timeAtStopOptions"
                  item-value="value"
                  item-text="label"
                  label="Time at Stop"
                  placeholder="minutes"
                  browser-autocomplete="off"
                />
              </v-flex>
              <v-flex>
                {{ currentSpotTime | formatTime(spotTimezone) }}
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-end>
              <v-btn
                :id="`${stopId}-reservation-itinerary-button-set-spot-modal-false`"
                class="btn-secondaryaction"
                @click="isSpotTimeModalOpen = false"
              >
                Close
              </v-btn>
              <v-btn
                :id="`${stopId}-reservation-itinerary-button-submit-spot-time`"
                class="btn-primaryaction mr-0"
                @click="updateSpotHandler"
              >
                Submit
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import { updateSpotTimes, deleteSpotTime } from '@/services/reservations'

const MILLIS_TO_MINUTES = 1000 * 60

export default {
  filters: {
    formatTime(value, zone) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
  },
  props: {
    reservationId: { type: Number, default: null },
    tripId: { type: Number, default: null },
    companyId: { type: Number, default: null },
    activeReservation: { type: Boolean, default: false },
    activeSpot: { type: Boolean, default: false },
    type: { type: String, default: null },
    stopId: { type: Number, default: null },
    spotTime: { type: String, default: null },
    stopTime: { type: String, default: null },
    parentSpotTime: { type: String, default: null },
    spotTimezone: { type: String, default: null },
  },
  data() {
    return {
      isSpotTimeModalOpen: false,
      currentSpotTimeSelection: null,
      timeAtStopOptions: [
        {
          label: '5 Minutes',
          value: 5,
        },
        {
          label: '10 Minutes',
          value: 10,
        },
        {
          label: '15 Minutes',
          value: 15,
        },
        {
          label: '20 Minutes',
          value: 20,
        },
        {
          label: '25 Minutes',
          value: 25,
        },
        {
          label: '30 Minutes',
          value: 30,
        },
        {
          label: '45 Minutes',
          value: 45,
        },
        {
          label: '60 Minutes',
          value: 60,
        },
      ],
    }
  },
  computed: {
    currentSpotTime() {
      const durationDateTime = Duration.fromMillis(
        this.currentSpotTimeSelection * MILLIS_TO_MINUTES
      )
      return DateTime.fromISO(this.stopTime).minus(durationDateTime).toISO()
    },
  },
  methods: {
    updateSpotTimes,
    deleteSpotTime,
    async deleteSpotHandler() {
      const payload = {
        tripId: this.tripId,
        companyId: this.companyId,
        stopId: this.stopId,
        spotTime: this.spotTime,
        active: false,
      }
      await this.deleteSpotTime(payload)
      this.$emit('refresh')
    },
    async updateSpotHandler() {
      const payload = [
        {
          tripId: this.tripId,
          companyId: this.companyId,
          stopId: this.stopId,
          spotTime: this.currentSpotTime,
          active: true,
        },
      ]
      await this.updateSpotTimes(this.reservationId, payload)
      this.isSpotTimeModalOpen = false
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.spot-time {
  font-weight: bold;
  &.child {
    color: $primary;
  }
}

.spot-time-button {
  cursor: pointer;
  color: $primary;
}
</style>
