<template>
  <v-layout v-if="loaded" column>
    <v-layout row wrap class="padding-t-7 padding-l-7">
      <v-flex
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Quote Number</p>
        <p class="margin-a-0 font-18 font-weight-bold">
          <router-link
            :id="`${id}-reservation-quote-link-to-quote`"
            :to="{ name: `quotes.view`, params: { id: quoteId } }"
          >
            {{ quoteId }}
          </router-link>
        </p>
      </v-flex>

      <v-flex
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Created Date</p>
        <p class="margin-a-0 font-18 font-weight-bold">
          {{ quoteCreatedOnDate }}
          <br />
          {{ quoteCreatedOnTime }}
        </p>
      </v-flex>

      <v-flex
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Quoted By</p>
        <p class="margin-a-0 font-18 font-weight-bold">{{ createdBy }}</p>
      </v-flex>

      <v-flex
        v-if="leadSource"
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Lead Souce</p>
        <p class="margin-a-0 font-18 font-weight-bold">{{ leadSource }}</p>
      </v-flex>

      <v-flex
        v-if="checkoutPageName"
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Brand</p>
        <p class="margin-a-0 font-18 font-weight-bold">
          {{ checkoutPageName }}
        </p>
      </v-flex>

      <v-flex
        v-if="pricingMethod"
        column
        class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
      >
        <p class="margin-a-0 font-16">Pricing Method</p>
        <p class="margin-a-0 font-18 font-weight-bold">
          {{ PRICING_METHODS_MAP[pricingMethod] }}
        </p>
      </v-flex>
    </v-layout>

    <v-flex xs12>
      <v-layout class="padding-l-7">
        <v-flex
          v-if="accountExecutiveName"
          column
          class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
        >
          <p class="margin-a-0 font-16">Account Executive</p>
          <p class="margin-a-0 font-18 font-weight-bold">
            {{ accountExecutiveName }}
          </p>
        </v-flex>

        <v-flex
          v-if="opportunityId"
          column
          class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
        >
          <p class="margin-a-0 font-16">Opportunity ID</p>
          <p class="margin-a-0 font-18 font-weight-bold">
            {{ opportunityId }}
          </p>
        </v-flex>

        <v-flex
          column
          class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
        >
          <p class="margin-a-0 font-16">Contract ID</p>
          <p class="margin-a-0 font-18 font-weight-bold">
            <router-link
              :id="`${id}-reservation-quote-link-to-contract`"
              :to="{ name: `contracts.view`, params: { id: contractId } }"
            >
              {{ contractId }}
            </router-link>
          </p>
        </v-flex>

        <v-flex
          v-if="contractName"
          column
          class="min-w-200 max-w-200 padding-b-5 padding-l-5 margin-b-5 border-solid border-gray-new"
        >
          <p class="margin-a-0 font-16">Contract Name</p>
          <p class="margin-a-0 font-18 font-weight-bold">
            {{ contractName }}
          </p>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'

const PRICING_METHODS_MAP = {
  single_price: 'Single Price',
  bids: 'Bids',
  category: 'Category',
}

export default {
  props: {
    quoteId: { type: Number, default: null },
    id: { type: String, default: null },
  },
  data() {
    return {
      loaded: false,
      createdOn: null,
      createdBy: null,
      pricingMethod: null,
      checkoutPageName: null,
      accountExecutiveName: null,
      opportunityId: null,
      leadSource: null,
      contractId: null,
      contractName: null,
      contractCreatedBy: null,
      PRICING_METHODS_MAP,
    }
  },
  computed: {
    quoteCreatedOnDate() {
      return this.createdOn
        ? DateTime.fromISO(this.createdOn).toFormat('MM/dd/yyyy')
        : ''
    },
    quoteCreatedOnTime() {
      return this.createdOn
        ? DateTime.fromISO(this.createdOn).toFormat('hh:mm a')
        : ''
    },
  },
  async created() {
    await this.loadQuote()
    this.loaded = true
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    async loadQuote() {
      try {
        const quoteData = await this.$store.dispatch(
          'quotes/getV2Quote',
          this.quoteId
        )
        this.createdOn = quoteData.data?.quote?.createdOn
        this.createdBy = quoteData.data?.quote?.createdByName
        this.pricingMethod = quoteData.data?.quote?.pricingMethod
        this.checkoutPageName = quoteData.data?.quote?.checkoutPage?.label
        this.accountExecutiveName = quoteData.data?.quote?.accountExecutiveName
        this.opportunityId = quoteData.data?.quote?.opportunityId
        this.leadSource = quoteData.data?.quote?.leadSourceType?.label
        this.contractId = quoteData.data?.quote?.contractId
        this.contractName = quoteData.data?.quote?.contract?.contractName
        this.contractCreatedBy = quoteData.data?.quote?.contract?.createdByName
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error loading quote',
        })
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.column {
  border-width: 0 0 0 3px;
}
</style>
