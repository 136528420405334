<template>
  <div id="reservation-payments" class="reservation-payments">
    <v-layout row justify-start full-width align-center>
      <v-flex shrink>
        <v-btn-toggle
          :id="`${id}-reservation-payments-button-toggle-data-type-changed-broker`"
          v-model="paymentDataType"
          mandatory
          class="type-toggler"
          @change="paymentDataTypeChangedByBroker"
        >
          <v-btn
            v-if="!canViewOpsTotal"
            :id="`${id}-reservation-payments-button-toggle-data-type-changed-client`"
            depressed
            active-class="primary"
            value="client"
          >
            Client
          </v-btn>
          <v-btn
            v-if="!isRA"
            :id="`${id}-reservation-payments-button-display-provider-list`"
            depressed
            :disabled="!providerList || !providerList[0]"
            active-class="primary"
            value="provider"
          >
            Provider
          </v-btn>
        </v-btn-toggle>
        <span v-if="paymentDataType === 'client'">{{ authPaymentCopy }}</span>
      </v-flex>
      <v-layout
        v-if="paymentDataType === 'provider'"
        row
        wrap
        style="align-items: center"
      >
        <v-menu ref="menuRef">
          <template #activator="{ on }">
            <v-btn
              :id="`${id}-reservation-payments-button-provider-display-list`"
              class="action-button"
              v-on="on"
            >
              {{ currentProvider.managedId }} -
              {{ currentProvider.companyName }} &nbsp;&nbsp;
              <CRIcon view-box="0 0 24 24">keyboard_arrow_down</CRIcon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-for="(provider, index) in providerList"
              :key="index"
              @click.stop="setProvider(provider, true)"
            >
              <v-list-tile-title>
                {{ provider.managedId }} - {{ provider.companyName }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <ReservationPaymentsPaymentTerms
          v-if="isAcceleratedPaymentsEnabled"
          :payment-terms="currentProviderPaymentTerms"
          :original-terms="currentProviderOriginalTerms"
          :referral-status="currentProvider.referralStatus"
          :can-view-payment-terms="currentProvider.canViewPaymentTerms"
        />
        <p
          v-if="isPaymentTermsDisputesEnabled && !!currentProviderPaymentTerms"
          class="edit-payment-terms-button"
          @click="editPaymentTerms"
        >
          Edit Payment Terms
        </p>
      </v-layout>
      <v-spacer />
      <v-flex v-if="reservationType !== 1" shrink>
        <v-menu>
          <template #activator="{ on }">
            <v-btn
              :id="`${id}-reservation-payments-button-payment-actions`"
              :class="`action-button mr-0 mb-3 ${
                paymentActionsAreActive ? '' : 'no-button-border'
              }`"
              :disabled="!paymentActionsAreActive"
              v-on="on"
            >
              Payment Actions
              <CRIcon view-box="0 0 24 24">keyboard_arrow_down</CRIcon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-if="paymentDataType === 'provider'"
              :id="`${id}-reservation-payments-list-title-pay-provider`"
              @click="showDialog('payProvider')"
            >
              <v-list-tile-title>Pay Provider</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="paymentDataType === 'client'"
              :id="`${id}-reservation-payments-list-title-collect-payment`"
              @click="showDialog('collectPayment')"
            >
              <v-list-tile-title>Collect Payment</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`${id}-reservation-payments-list-title-add-refund`"
              @click="showDialog('addRefund')"
            >
              <v-list-tile-title>Add Refund</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`${id}-reservation-payments-list-title-add-charge`"
              @click="showDialog('addCharge')"
            >
              <v-list-tile-title>Add Charge</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`${id}-reservation-payments-list-title-reduce-charge`"
              @click="showDialog('reduceCharge')"
            >
              <v-list-tile-title>Reduce Charge</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="showCancellationPenaltyAction"
              :id="`${id}-reservation-payments-list-title-cancellation-penalty`"
              @click="showDialog('cancellationPenalty')"
            >
              <v-list-tile-title>Cancellation Penalty</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`${id}-reservation-payments-list-title-new-invoice`"
              @click="showDialog('invoice')"
            >
              <v-list-tile-title>Invoice</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`${id}-reservation-payments-list-title-change-due-date`"
              @click="showDialog('changeDueDate')"
            >
              <v-list-tile-title>Change Due Date</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="paymentDataType === 'client'"
              :id="`client-payments-list-title-change-balance-payment-method`"
              @click="showDialog('changePaymentMethod')"
            >
              <v-list-tile-title>Change Payment Method</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout column class="summary-container">
      <v-data-table
        :headers="headers"
        :items="items"
        :hide-actions="true"
        :align="'end'"
      >
        <template #items="props">
          <td v-if="canViewEffectiveDate" align="center">
            <component :is="actionsComponent" :row="props.item" />
          </td>
          <td align="center" style="white-space: pre-wrap">
            {{ formatDate(props.item.createdOn) }}
          </td>
          <td
            v-if="canViewEffectiveDate"
            align="center"
            style="white-space: pre-wrap"
          >
            {{ formatDate(props.item.formattedEffectiveDate) }}
          </td>
          <td
            align="left"
            style="white-space: nowrap; padding-left: 10px !important"
          >
            {{ props.item.description }}
            <v-tooltip
              v-if="
                props.item.classificationLabel || props.item.refundPaymentMethod
              "
              right
            >
              <template #activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <span v-if="props.item.classificationLabel">
                Classification: {{ props.item.classificationLabel }}
                <br />
              </span>
              <span v-if="props.item.refundPaymentMethod">
                Payment Method: {{ props.item.refundPaymentMethod }}
              </span>
            </v-tooltip>
          </td>
          <td width="400px" align="left">
            <div v-html="props.item.htmlNotes || props.item.notes" />
          </td>
          <td width="400px" align="left">
            <div
              v-html="props.item.htmlCustomerNotes || props.item.customerNotes"
            />
          </td>
          <td>{{ props.item.charge }}</td>
          <td>{{ props.item.payment }}</td>
          <td class="balance">
            {{ props.item.balance }}
          </td>
        </template>
        <template #footer>
          <td
            v-if="canViewEffectiveDate"
            colspan="6"
            class="pdl-40"
            align="start"
          >
            Totals
          </td>
          <td v-else colspan="4" class="pdl-40" align="start">Totals</td>

          <td align="end" class="pl-0 pr-3">
            {{ currencyFilter(displayedCharges) }}
          </td>
          <td align="end" class="pl-0 pr-3">
            {{ currencyFilter(displayedPayments) }}
          </td>
          <td align="end" class="pl-0 pr-3">
            {{ currencyFilter(displayedBalance) }}
          </td>
        </template>
      </v-data-table>
    </v-layout>
    <PaymentSidebarWrapper
      :id="'reservation-payments-sidebar'"
      v-model="dialogType"
      :row="actionReservationRow"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import reservationPaymentsActionsWrapper from '@/components/ReservationPaymentsActionsWrapper'
import ReservationPaymentTableActions from '@/components/ReservationPaymentTableActions'
import ReservationPaymentsPaymentTerms from '@/components/ReservationPaymentsPaymentTerms.vue'
import transaction from '@/services/transaction'

import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { friendlyDate } from '@/utils/time'
import authPayment from '@/services/authPayment'
import tracking from '@/services/tracking'

import { SplitFeatureFlag } from '@/utils/enum'

const CANCELLATION_PENALTY_CHARGE_TYPE_ID = 19

export default {
  components: {
    PaymentSidebarWrapper,
    ReservationPaymentsPaymentTerms
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    amount: {
      type: Number,
      default: null,
    },
    balance: {
      type: Number,
      default: null,
    },
    hash: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      default: null,
    },
    customerFirstName: {
      type: String,
      default: '',
    },
    customerEmail: {
      type: String,
      default: '',
    },
    referredTo: {
      type: Array,
      default: () => [],
    },
    reservationId: {
      type: Number,
      default: null,
    },
    reservationType: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: null,
    },
    paymentStatus: {
      type: String,
      default: '',
    },
    dueDate: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    quoteId: {
      type: Number,
      default: null,
    },
    version: {
      type: Number,
      default: null,
    },
    tripId: {
      type: Number,
      default: null,
    },
    paymentMethodTypes: {
      type: Array,
      required: true,
    },
    balancePaymentMethods: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      paymentDataType: null,
      currencyFilter,
      currentReservationId: null,
      currentProvider: null,
      currentProviderBalance: 0,
      currentProviderAmount: 0,
      actionsDialog: false,
      dialogType: '',
      displayedCharges: 0,
      displayedPayments: 0,
      actionReservationRow: {},
      headers: [],
      items: [],
      actionsComponent: null,
      authPaymentOnCheckout: null,
      preAuthorizedAmount: null,
      authorizedOn: null,
      meta: null,
      mask: null,
      label: null,
      trackingSummariesByReferralId: null,
      isAcceleratedPaymentsEnabled: false,
      isPaymentTermsDisputesEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    canViewOpsTotal() {
      return this.$store.getters['auth/canViewOpsTotal']
    },
    hasCancellationPenaltyCharge() {
      return this.items.some(
        (transaction) =>
          transaction.chargeTypeId == CANCELLATION_PENALTY_CHARGE_TYPE_ID
      )
    },
    authPaymentCopy() {
      if (this.preAuthorizedAmount && this.authorizedOn && this.meta) {
        return `${
          this.label?.charAt(0)?.toUpperCase() + this.label?.slice(1)
        } *${this.mask} was pre-authorized for ${currencyFilter(
          this.preAuthorizedAmount
        )} at checkout on ${friendlyDate(this.authorizedOn)}`
      } else if (this.meta) {
        return `${
          this.label?.charAt(0)?.toUpperCase() + this.label?.slice(1)
        } *${this.mask} used at checkout. No funds are held.`
      }
      return ''
    },
    showCancellationPenaltyAction() {
      const referral = this.referredTo.find(
        (referral) => referral.reservationId == this.currentReservationId
      )
      if (!referral) {
        return false
      }
      return (
        this.paymentDataType === 'provider' &&
        referral.referralStatus === 'rejected' &&
        !this.hasCancellationPenaltyCharge
      )
    },
    providerList() {
      return this.referredTo?.filter((provider) =>
        ['confirmed', 'offered', 'reoffered', 'accepted', 'rejected'].includes(
          provider.referralStatus
        )
      )
    },
    displayedBalance() {
      if (this.paymentDataType === 'provider') {
        return this.currentProviderBalance
      }
      return this.balance
    },
    paymentActionsAreActive() {
      if (!this.active) {
        return false
      }

      if (this.paymentDataType !== 'provider') {
        return true
      }

      if (
        this.currentProvider?.referralStatus === 'rejected' &&
        this.currentProvider?.balance === 0 &&
        this.displayedCharges === 0 &&
        this.displayedPayments === 0 &&
        !this.showCancellationPenaltyAction
      ) {
        return false
      }

      return true
    },
    canViewEffectiveDate() {
      return this.$store.getters['auth/hasPermission']('canViewReservationPaymentEffectiveDate')
    },
    referralIds() {
      return this.providerList
        .map(provider => provider?.reservationId)
        .filter(referralId => !!referralId)
    },
    currentProviderPaymentTerms() {
      if (!this.trackingSummariesByReferralId) {
        return null
      }

      return this.trackingSummariesByReferralId[this.currentProvider?.reservationId]?.paymentTerms
    },
    currentProviderOriginalTerms() {
      if (!this.trackingSummariesByReferralId) {
        return null
      }

      return this.trackingSummariesByReferralId[this.currentProvider?.reservationId]?.systemSelectedPaymentTerms
    }
  },
  watch: {
    reservationId() {
      this.$nextTick(() => {
        this.init()
      })
    },
    dialogType() {
      if (this.dialogType === '') {
        this.onCloseDialog()
      }
    },
    referredTo() {
      this.hydrate()
    },
  },
  async mounted() {
    this.isAcceleratedPaymentsEnabled = await this.isFeatureEnabled(SplitFeatureFlag.AcceleratedPayments)
    this.isPaymentTermsDisputesEnabled = await this.isFeatureEnabled(SplitFeatureFlag.PaymentTermsDisputes)

    this.init()
    await this.cancellationPaymentInfo()
    EventBus.$on('refresh-reservation-payments', () => this.hydrate())
    if (this.canViewEffectiveDate) {
      this.headers.push({
        text: '',
        value: '',
        sortable: false,
        align: 'center',
      })
    }
    this.headers.push({
      text: 'Created Date',
      value: 'createdOn',
      sortable: false,
      align: 'center',
    })
    if (this.canViewEffectiveDate) {
      this.headers.push({
        text: 'Effective Date',
        value: 'effectiveDate',
        sortable: false,
        align: 'center',
      })
    }
    this.headers.push(
      {
        text: 'Description',
        value: 'description',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Office Notes',
        value: 'flattenedNotes',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Payment Notes',
        value: 'flattenedCustomerNotes',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Charge',
        value: 'charge',
        sortable: false,
        align: 'end',
      },
      {
        text: 'Payment',
        value: 'payment',
        sortable: false,
        align: 'end',
      },
      {
        text: 'Balance',
        value: 'balance',
        sortable: false,
        align: 'end',
        class: 'balance-header',
      }
    )
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async init() {
      this.currentReservationId = this.reservationId

      await this.setProvider(this.providerList?.[0])

      const reservationPaymentsActions = await reservationPaymentsActionsWrapper(
        ReservationPaymentTableActions,
        null
      )
      this.actionsComponent = reservationPaymentsActions

      if (this.canViewOpsTotal) {
        this.paymentDataTypeChangedByBroker('provider')
      } else {
        this.paymentDataTypeChangedByBroker('client')
      }

      if (this.isAcceleratedPaymentsEnabled) {
        const summaryResponse = await tracking.getTrackingSummary(this.referralIds)
        const trackingSummaries = summaryResponse?.data?.reservationTrackingSummary
        this.trackingSummariesByReferralId = trackingSummaries.reduce((previous, summary) => ({...previous, [summary.referralId]: summary}), {})
      }

    },
    async hydrate() {
      await this.cancellationPaymentInfo()
      const data = await transaction
        .byReservationId(this.currentReservationId)
        .then((data) => data.data)
        .catch()

      this.items = (data || []).map((t) => {
        const result = {}
        result.createdOn = DateTime.fromISO(t.createdOn).toLocaleString(
          DateTime.DATETIME_SHORT
        )
        result.chargeTypeId = t.chargeTypeId
        result.description = t.description
        result.classificationLabel = t.classificationLabel
        result.refundPaymentMethod = t.refundPaymentMethod
        result.notes = t.notes
        result.customerNotes = t.customerNotes
        result.charge = t.charge ? this.currencyFilter(t.charge) : null
        result.payment = t.payment ? this.currencyFilter(t.payment) : null

        result.amount = this.currencyFilter(t.amount)
        result.balance = this.currencyFilter(t.balance)
        result.formattedEffectiveDate = DateTime.fromISO(
          t.effectiveDate
        ).toLocaleString(DateTime.DATETIME_SHORT)
        result.effectiveDate = t.effectiveDate
        result.transactionId = t.transactionId
        result.paymentId = t.paymentId
        result.refundId = t.refundId
        result.referenceId = t.referenceId

        return result
      })

      this.displayedCharges = data.reduce((acc, item) => {
        acc += item.charge || 0
        return acc
      }, 0)

      this.displayedPayments = data.reduce((acc, item) => {
        acc += item.payment || 0
        return acc
      }, 0)

      this.actionReservationRow = {
        reservationId: this.reservationId,
        version: this.version,
        customerId: this.customerId,
        balance: this.balance,
        hash: this.hash,
        reservationType: this.reservationType,
        transactions: this.transactions,
        companyId: this.companyId,
        customerName: this.customerFirstName,
        customerEmail: this.customerEmail,
        companyCheckoutPageId: this.$attrs.companyCheckoutPageId,
        tripId: this.tripId,
        paymentMethodTypes: this.paymentMethodTypes,
        balancePaymentMethods: this.balancePaymentMethods,
      }

      if (this.paymentDataType === 'provider') {
        const referral = this.referredTo.find(
          (ref) => ref.reservationId === this.currentReservationId
        )
        if (!referral) {
          return
        }

        this.currentProviderAmount = referral.referralAmount
        this.currentProviderBalance = referral.balance

        this.actionReservationRow = {
          reservationId: this.currentReservationId,
          version: referral.reservationVersion,
          balance: referral.balance,
          reservationType: 1,
        }
      }
    },
    paymentDataTypeChangedByBroker(newType) {
      if (newType === 'client') {
        this.currentReservationId = this.reservationId
        this.hydrate()
        return
      }
      if (newType === 'provider') {
        this.currentReservationId = this.currentProvider?.reservationId
        this.hydrate()
      }
    },
    async setProvider(provider, setAsCurrentView) {
      const parentMenu = this.$refs.menuRef
      if (parentMenu) {
        parentMenu.isActive = false
      }

      this.currentProvider = provider

      if (setAsCurrentView) {
        this.currentReservationId = provider.reservationId
        await this.hydrate()
      }
    },
    onCloseDialog() {
      this.actionsDialog = false
      this.dialogType = ''
      this.$store.dispatch('reservations/refreshReservationById', {
        reservationId: this.reservationId,
        viewRejectedReferrals: true,
      })
      window.setTimeout(() => this.hydrate(), 1000)
    },
    showDialog(type) {
      if (type === 'collectPayment' && this.paymentStatus === 'fully_paid') {
        this.showAlert({
          message: `Payment may not be collected. ${this.reservationId} is fully paid.`,
          type: 'error',
        })
        return
      }

      if (type === 'cancellationPenalty') {
        const component = () =>
          import('@/components/CancellationPenaltySidebar.vue')
        const referral = this.referredTo.find(
          (referral) => referral.reservationId == this.currentReservationId
        )
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            reservationId: this.reservationId,
            referral: referral,
            title: 'Cancellation Penalty',
          },
          component,
        })

        return
      }

      // Provider's reservation invoice should not be sent to the customer
      if (type === 'invoice' && this.paymentDataType === 'provider') {
        this.actionReservationRow.reservationId = this.reservationId
      }

      if (type === 'changePaymentMethod') {
        this.actionReservationRow.reservationId = this.reservationId
        this.actionReservationRow.tripId = this.tripId
        this.actionReservationRow.paymentMethodTypes = this.paymentMethodTypes
        this.actionReservationRow.balancePaymentMethods = this.balancePaymentMethods
      }

      this.dialogType = type
    },
    editPaymentTerms() {
      const component = () => import('./EditPaymentTermsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          referralId: this.currentProvider?.reservationId,
          providerVehicles: this.currentProvider?.assignedVehicles,
          trackingSummary: this.trackingSummariesByReferralId[this.currentProvider?.reservationId],
          title: 'Edit Payment Terms',
        },
        component,
      })
    },
    formatDate(date) {
      const split = date.split(',')
      if (split.length == 2) {
        return `${split[0]}\n${split[1]}`
      }
      return date
    },
    async cancellationPaymentInfo() {
      const authPaymentResponse = await authPayment.getQuoteAuthHold(
        this.quoteId
      )
      const authPaymentResult = authPaymentResponse?.data?.authPayments
      //get earliest authPayment as checkout transaction
      if (authPaymentResult && authPaymentResult.length > 0) {
        this.authPaymentOnCheckout = authPaymentResult.reduce(
          (earliestPayment, currentPayment) =>
            currentPayment.createdOn < earliestPayment.createdOn
              ? currentPayment
              : earliestPayment
        )

        this.meta = JSON.parse(this.authPaymentOnCheckout?.meta)
        const { mask, type_label } = this.meta
        this.mask = mask
        this.label = type_label

        if (
          this.authPaymentOnCheckout?.isCaptured === false &&
          this.authPaymentOnCheckout?.isVoided === false &&
          DateTime.fromISO(this.authPaymentOnCheckout?.expiresOn) >
            DateTime.local()
        ) {
          this.preAuthorizedAmount = this.authPaymentOnCheckout?.amount
          this.authorizedOn = this.authPaymentOnCheckout?.createdOn
        } else {
          this.preAuthorizedAmount = null
          this.authorizedOn = null
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.no-button-border {
  border: none !important;
}

.reservation-payments {
  padding: 0 8px 8px;

  .type-toggler {
    margin-right: 1em;
    border-radius: 5px;
    box-shadow: none;

    .v-btn {
      height: unset;
      padding: 12px 40px;

      &.primary {
        color: $white;
        background-color: $blue-new !important;
        border-color: $blue-new !important;
      }
    }
  }

  .action-button {
    color: $gray;
    font-weight: 700;
  }

  .edit-payment-terms-button {
    cursor: pointer;
    color: $primary;
    padding-left: 8px !important;
    margin-bottom: 0px;
  }

  .min-height {
    min-height: 65px;
    max-height: 65px;
  }

  .summary-container {
    .container {
      padding-left: 0;
    }

    .summary-column {
      font-size: 16px;
      background-color: $blue-pale;

      .item-row {
        padding: 15px 25px;

        .amount {
          color: $primary;
          text-align: right;
        }
      }

      .balance-row {
        padding: 15px 25px;
        font-size: 14px;
        color: $white;
        background-color: $gray-dark;

        .flex:last-of-type {
          text-align: right;
        }
      }
    }
  }
}

.pdl-40 {
  padding-left: 40px !important;
}

::v-deep .v-datatable tbody td.balance {
  padding-right: 15px !important;
}

::v-deep table {
  margin-top: 0;
  border: 1px solid $gray-light;

  &.v-table thead tr:first-child th {
    padding: 17px 10px !important;
    font-size: 14px !important;
  }

  &.v-table thead tr th.balance-header {
    padding-right: 15px !important;
  }

  tfoot {
    height: 45px;
    font-size: 20px;
    line-height: 2;
    color: $white;
    text-align: center;
    background-color: $gray;
  }
}
</style>
