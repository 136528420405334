<template>
  <v-flex sm6>
    <div class="trip-note">
      <v-layout row justify-start align-center mb-2>
        <p class="reservation-notes__text">{{ title }}</p>
        <p class="edit-button" @click="$emit('edit-trip-notes')">Edit</p>
      </v-layout>
      <CRIcon
        v-if="notesOverflowed"
        :class="notesExpanded ? 'flipped' : ''"
        class="expansion-arrow"
        view-box="0 0 24 24"
        @click.native="toggleNotesExpanded"
      >
        keyboard_arrow_down
      </CRIcon>
    </div>
    <div ref="tripNotes" class="note-line" :class="notesClass" v-html="notes" />
    <div class="spacer" :class="notesClass"></div>
  </v-flex>
</template>

<script>
const PARAGRAPH_MARGIN = 16

export default {
  props: {
    title: { type: String, required: true },
    notes: { type: String, default: '' },
  },
  data() {
    return {
      notesExpanded: false,
      notesOverflowed: false,
    }
  },
  computed: {
    notesClass() {
      const notesText = this.notes?.replace(/<[^>]+>/g, '').trim()

      if (notesText && !this.notesExpanded) {
        return 'truncated-notes'
      } else {
        return ''
      }
    },
    hasNotes() {
      return !!(this.notes || '').replace(/<[^>]+>/g, '').trim()
    },
  },
  watch: {
    notes: {
      handler() {
        this.notesExpanded = false
        this.$nextTick(this.updateNotesOverflow)
      },
    },
  },
  mounted() {
    this.updateNotesOverflow()
  },
  methods: {
    toggleNotesExpanded() {
      this.notesExpanded = !this.notesExpanded
    },
    updateNotesOverflow() {
      this.notesOverflowed =
        this.$refs.tripNotes.offsetHeight <
        this.$refs.tripNotes.scrollHeight - PARAGRAPH_MARGIN
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  cursor: pointer;
  color: $primary;
  padding-left: 8px !important;
}
.flex {
  padding: 10px;
  border-bottom: 1.5px solid $background-hover;
  &:first-child {
    border-right: 1.5px solid $background-hover;
  }
}

.truncated-notes {
  height: 60px;
  overflow: hidden;

  &.spacer {
    height: 18px;
  }
}

.trip-note {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.note-line {
  min-height: 29px;
  overflow-wrap: break-word;
}

.expansion-arrow {
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  &.flipped {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
}
</style>
